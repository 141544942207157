import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  Typography,
  Pagination,
} from "@mui/material";
import { CSVLink } from "react-csv";
import styled from "@emotion/styled";
import Sidebar from "../../../components/profileSidebar/Sidebar";
import Select from "@mui/material/Select";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import { toast } from "react-toastify";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { ApiConfig } from "../../../services/ApiConfig";
// import Pagination from "../../../components/common/Pagination";
import dayjs from "dayjs";
import moment from "moment";
import PasswordResetModal from "../../../components/PasswordResetModal";
import MainFilter from "../../../components/common/MainFilter";
import customAxios from "../../../utils/customAxios";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#523168",
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   backgroundColor: theme.palette.action.hover,
  //   border: "1px solid black",

  " &:last-child th": {
    // border: "1px solid black",
  },
}));

function createData(
  DateAndTime,
  LoginFrom,
  IPAddress,
  Location,
  Source,
  Status,
  Action
) {
  return {
    DateAndTime,
    LoginFrom,
    IPAddress,
    Location,
    Source,

    Status,
    Action,
  };
}

// const rows = [
//   createData(
//     "Apr 13, 2022, 4:41:38PM",
//     "Chrome",
//     "182.71.75.106",
//     "Delhi",
//     "Web",
//     "Logged In",
//     "Logout"
//   ),
//   createData(
//     "Apr 13, 2022, 4:41:38PM",
//     "Chrome",
//     "182.71.75.106",
//     "Delhi",
//     "Web",
//     "Logged In",
//     "Logout"
//   ),
//   createData(
//     "Apr 13, 2022, 4:41:38PM",
//     "Chrome",
//     "182.71.75.106",
//     "Delhi",
//     "Web",
//     "Logged In",
//     "Logout"
//   ),
//   createData(
//     "Apr 13, 2022, 4:41:38PM",
//     "Chrome",
//     "182.71.75.106",
//     "Delhi",
//     "Web",
//     "Logged In",
//     "Logout"
//   ),
//   createData(
//     "Apr 13, 2022, 4:41:38PM",
//     "Chrome",
//     "182.71.75.106",
//     "Delhi",
//     "Web",
//     "Logged In",
//     "Logout"
//   ),
// ];

const SessionManagemant = () => {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const [rows, setRows] = useState([]);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [search, setsearch] = useState("");
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [isClear, setIsClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sessionList, setSessionList] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("");
  const [currentId, setCurrentId] = useState("");
  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
  };
  const [isSessionListUpdated, setIsSessionListUpdated] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleCloseModals = () => {
    setLogoutModalOpen(false);
    // You can perform additional actions on modal close if needed
  };
  const handlePageChange = (event, value) => {
    setPage(value); // Update the current page
  };
  useEffect(() => {
    if (isClear) {
      getUserSessionDetails();
    }
  }, [isClear]);
  useEffect(() => {
    getUserSessionDetails();
  }, [isSessionListUpdated]);

  const getUserSessionDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllSessionDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          page: page,
          limit: 10,
          location: search !== "" ? search : undefined,
          fromDate: fromDate
            ? moment(fromDate).format("YYYY-MM-DD").toString()
            : undefined,
          toDate: toDate
            ? moment(toDate).format("YYYY-MM-DD").toString()
            : undefined,
        },
      });
      if (res.data.statusCode === 200) {
        setNoOfPages(res?.data?.result?.totalPages);
        setSessionList(res?.data?.result?.data);
        // setSessionList(res?.data?.result || []);

        console.log("session", res?.data);
      } else {
        setNoOfPages(1);
        setIsClear(false);
        setPage(1);
        setSessionList([]);
        setIsLoading(false);
      }
      setIsClear(false);
    } catch (error) {
      console.log(error);
      setNoOfPages(1);
      setIsClear(false);
      setPage(1);
      setSessionList([]);
      setIsLoading(false);
    }
  };

  const sessionLogout = async () => {
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.userLogout,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          session_id: currentId,
        },
      });
      if (res.data.statusCode == 200) {
        // setUserDetails(res?.data?.result);
        // setRows(res?.data?.result);

        if (
          currentId == JSON.parse(sessionStorage.getItem("user"))?.session_id
        ) {
          sessionStorage.removeItem("userToken");

          navigate("/login");
        }

        setIsSessionListUpdated((e) => !e);
        // console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClear = () => {
    setsearch("");
    setToDate(null);
    setFromDate(null);
    setIsClear(true);
  };

  useEffect(() => {
    getUserSessionDetails();
  }, [page, fromDate, toDate, search]);
  console.log("sessionList", sessionList, search);
  const dataWithSerialNumber =
    sessionList && Array.isArray(sessionList)
      ? sessionList.map((user, index) => {
          return {
            srNo: index + 1,
            login: user?.login_from,
            ip: user?.ip_address,
            location: user?.location,
            source: user?.source,
            status: user?.status,
            id: user?.id,
          };
        })
      : [];

  console.log(dataWithSerialNumber);

  const headers = [
    { label: "Sr. No.", key: "srNo" },
    { label: "Login From", key: "login" },
    { label: "IP Address", key: "ip" },
    { label: "Location", key: "location" },
    { label: "Source", key: "source" },
    { label: "Status", key: "status" },
  ];
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  console.log("noOfPages", noOfPages);
  const buttonStyle = {
    color: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.12)",
  };
  const PrevbuttonStyle = {
    color: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
  };
  const handleNext = () => {
    console.log("before", noOfPages, page);
    if (page < noOfPages) {
      setPage(page + 1); // Increment the page only if it's not the last page
      console.log("after", noOfPages, page);
    }
  };
  return (
    <div
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#f8f8f8",
      }}
    >
      <Box
        sx={{
          display: "flex",
          //   gap: "25px",
          alignItems: "center",
          paddingLeft: { sm: "70px", xs: "35px" },
          backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
        }}
      >
        <img
          style={{ cursor: "pointer", width: "30px" }}
          onClick={() => navigate("/my-profile")}
          src="/images/backicon.png"
        />
        <h1
          style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          className="profile-heading"
        >
          Session Management
        </h1>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",

          padding: { sm: "0.6% 70px 1rem 70px", xs: "1% 20px 1rem 20px" },
          // marginBottom: "-2rem",
        }}
      >
        <Box
          sx={{
            display: { md: "block", xs: "none" },

            margin: 0,
            padding: 0,
            height: "790px",
            // paddingBottom:"30px",
            width: "450px",

            overflow: "none",
            alignItems: "center",
            gap: "2rem",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
              display: "none", // For Safari and Chrome
            },
            "@media(max-width:768px)": {
              ml: "0px",
            },
          }}
        >
          <Sidebar />
        </Box>
        <div
          style={{
            width: "100%",
            // height: "78vh",
            overflow: "auto",
            backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
            padding: "20px 2%",
            overflowY: "scroll",
            height: "90vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: "15px",
            }}
          >
            <Typography
              sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              fontSize="24px"
              fontWeight={500}
            >
              User's Session History
            </Typography>
            <CSVLink
              // filename={"Session_Detalis.csv"}
              filename={`Session History_` + dayjs(new Date())?.format("lll")}
              data={dataWithSerialNumber}
              headers={headers}
              style={{
                color: "#fff",
                textDecoration: "none",
                background: "#523168",
                height: "58px",
                padding: "10px",
                borderRadius: "30px",
              }}
            >
              <Button
                sx={{ color: "#fff", fontSize: { md: "16px", xs: "12px" } }}
              >
                Download CSV{" "}
              </Button>
            </CSVLink>
          </div>
          {/* <Typography fontSize="16px" fontWeight={400} color="#202123BF" mt={1}>
            Lorem ipsum dolor sit amet consectetur. Sed tortor et.
          </Typography> */}
          <Box mt={3} mb={3}>
            <MainFilter
              type="session"
              handleClear={handleClear}
              search={search}
              setsearch={setsearch}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              getUserSessionDetails={getUserSessionDetails}
            />
          </Box>
          <TableContainer
            style={{
              width: "100%",
              marginTop: "24px",
              border: "1px solid #523168",
              borderRadius: "8px",
            }}
            // component={Paper}
          >
            <Table
              style={{ padding: "0px 20px" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    Date & Time
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    align="center"
                  >
                    Login From
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    align="center"
                  >
                    IP Address
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    align="center"
                  >
                    Location
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    align="center"
                  >
                    Source
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    align="center"
                  >
                    Status
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    align="center"
                  >
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              {sessionList?.length <= 0 && (
                <Typography>No session history found!!</Typography>
              )}
              <TableBody>
                {sessionList &&
                  sessionList?.map((row, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell
                        sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                        component="th"
                        scope="row"
                      >
                        {dayjs(row?.date_time)?.format("lll")}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          textTransform: "capitalize",
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {row?.login_from}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                        align="center"
                      >
                        {row?.ip_address}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                        align="center"
                      >
                        {row?.location}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          textTransform: "capitalize",
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                        align="center"
                      >
                        {row?.source}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                        align="center"
                      >
                        {row?.status == true ? "Logged In" : "Logged Out"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          onClick={(e) => {
                            // console.log(row.id);
                            setCurrentId(row.id);
                            handleOpenLogoutModal();
                          }}
                          variant="contained"
                          disabled={row?.status == false}
                          sx={{
                            color: "white",
                            backgroundColor: "#F83629",
                            borderRadius: "6px",
                            fontSize: "13px",
                            fontWeight: 500,
                            padding: "6px",
                            textTransform: "none",
                            "&:hover": {
                              //you want this to be the same as the backgroundColor above
                              backgroundColor: "#f64438",
                            },
                            //   padding: "10px",
                          }}
                        >
                          Logout
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/*   <FormControl sx={{ m: 1, minWidth: 90 }}>
              <Select
                value={age}
                onChange={handleChange}
                displayEmpty
                sx={{ padding: 0 }}
                inputProps={{ "aria-label": "Without label", padding: 0 }}
              >
                <MenuItem value="">25</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>*/}
          {/* {sessionList && noOfPages > 0 && ( */}
          {/* <Pagination
              count={1}
              page={page}
              // onChange={(e, value) => setPage(value)}
              onChange={handlePageChange}
            /> */}
          {/* )} */}
          <Box
            mt={3}
            mb={1}
            style={{
              display: noOfPages > 1 ? "flex" : "none",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={PrevbuttonStyle}
              variant="outlined"
              disabled={page === 1}
              onClick={handlePrevious}
            >
              Previous
            </Button>
            <Pagination
              count={noOfPages}
              page={page}
              className={
                currentTheme == "DARK" ? "pagination-dark" : "pagination"
              }
              onChange={handleChange}
              hideNextButton={true}
              hidePrevButton={true}
              sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            />
            <Button
              variant="outlined"
              disabled={page === noOfPages}
              onClick={handleNext}
              style={buttonStyle}
            >
              Next
            </Button>
          </Box>
        </div>
      </Box>
      <PasswordResetModal
        type="sessionlogout"
        sessionLogout={sessionLogout}
        open={isLogoutModalOpen}
        onClose={handleCloseModals}
      />
    </div>
  );
};

export default SessionManagemant;
