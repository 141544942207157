import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import QuizPercentage from "../../widgets/assessmentPersentage/QuizPercentage";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const QuizResponse = () => {
  const [quizAssessmentData, setQuizAssessmentData] = useState();
  const [totalMarks, setTotalMarks] = useState();
  const [maximumMarks, setMaximumMarks] = useState();
  const [percentage, setPercentage] = useState();
  const [feedback, setFeedback] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();

  console.log(location?.state?.data);

  const getTotalData = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.quizAnswersAutoCheckByAdviser,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          user_id: sessionStorage.getItem("userId"),
          module_id: location?.state?.data,
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result);
        setMaximumMarks(res?.data?.result?.totalQuestion);
        setTotalMarks(res?.data?.result?.correctCount);
        setPercentage(res?.data?.result?.percentage);
        // setPageLimit(res?.data?.result?.totalPages);
        // console.log(quizAssessmentData, "ghjghhggh");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getQuizScorfn = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getQuizScore,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          module_id: location?.state?.data,
          user_id: sessionStorage.getItem("userId"),
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result?.feedback);
        setFeedback(res?.data?.result?.feedback);
        // setQuizAssessmentData(res?.data?.result[0]?.quizManagements);
        // setPageLimit(res?.data?.result?.totalPages);
        // console.log(quizAssessmentData, "ghjghhggh");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getQuizResponse = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllCourseModulesQuizes,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          module_id: location?.state?.data,
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result[0]?.quizManagements);
        setQuizAssessmentData(res?.data?.result[0]?.quizManagements);
        // setPageLimit(res?.data?.result?.totalPages);
        // console.log(quizAssessmentData, "ghjghhggh");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTotalData();
    getQuizScorfn();
    getQuizResponse();
  }, []);
  return (
    <Container maxWidth="lg">
      <Box mt={4}>
        <QuizPercentage type="quiz" percentage={100} />
      </Box>{" "}
      <Box
        display="flex"
        alignItems="start"
        flexDirection="column"
        mt={2}
        // sx={{ marginLeft: "30px" }}
      >
        {" "}
        <Box display="flex" alignItems="center">
          <IconButton
            style={{ margin: "0 2rem 0 0" }}
            sx={{
              color: "#523168",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowCircleLeftIcon fontSize="large" />
          </IconButton>
          <Typography sx={{ textDecoration: "underline" }} variant="h4">
            Quiz Response
          </Typography>
        </Box>
        <>
          <Box
            mt={2}
            sx={{
              marginLeft: "80px",
              display: "flex",
              gap: "30px",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="300px"
              sx={{
                padding: "20px",
                borderRadius: "8px",
                border: "1px solid black",
                backgroundColor: "#ffd3aea3",
              }}
            >
              <Typography variant="h6">Total Questions </Typography>
              <Typography variant="h6">{maximumMarks || 0}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="300px"
              sx={{
                padding: "20px",
                borderRadius: "8px",
                border: "1px solid black",
                backgroundColor: "#ffd3aea3",
              }}
            >
              <Typography variant="h6">Score</Typography>
              <Typography variant="h6">{totalMarks || 0}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="300px"
              sx={{
                padding: "20px",
                borderRadius: "8px",
                border: "1px solid black",
                backgroundColor: "#ffd3aea3",
              }}
            >
              <Typography variant="h6">Percentage</Typography>
              <Typography variant="h6">{percentage || 0}</Typography>
            </Box>
          </Box>
          {feedback && (
            <Box
              component={OverlayScrollbarsComponent}
              sx={{ overflowY: "scroll", maxHeight: "300px" }}
            >
              <Typography variant="h6" mt={2} sx={{ marginLeft: "80px" }}>
                Feedback: {feedback}
              </Typography>
            </Box>
          )}
          <Box sx={{ pb: 10 }}>
            {" "}
            {quizAssessmentData?.map((data, i) => (
              <Box sx={{ marginLeft: "80px" }} mt={4} key={i}>
                <Typography variant="h6" fontSize="16.5px">
                  {i + 1}. {data?.question}
                </Typography>
                <Box display="flex">
                  {" "}
                  <Typography
                    color={
                      data?.correctAnswer === data?.quizAnswers[0]?.answer
                        ? "green"
                        : "red"
                    }
                    variant="body1"
                  >
                    <span style={{ color: "black" }}> A{i + 1}. </span>
                    {data?.quizAnswers[0]?.answer}
                  </Typography>
                  <Typography ml={2}>
                    {data?.correctAnswer === data?.quizAnswers[0]?.answer ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <Box display="flex">
                        {" "}
                        <CloseIcon style={{ color: "red" }} />{" "}
                        <Typography ml={10} color={"green"} variant="body1">
                          <span style={{ color: "black" }}>
                            Correct Answer:
                          </span>{" "}
                          {data?.correctAnswer}
                        </Typography>
                      </Box>
                    )}
                    {/* {data?.correctAnswer === !data?.quizAnswers[0]?.answer ? (
                      <Typography style={{ color: "green" }}>
                        {data?.correctAnswer}
                      </Typography>
                    ) : (
                      ""
                    )} */}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      </Box>
    </Container>
  );
};

export default QuizResponse;
