import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Container } from "@mui/material";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const [content, setContent] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const getStaticContent = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.findStaticContentByType,
        data: {
          content_type: "aboutUs",
        },
      });
      if (res.data?.statusCode == 200) {
        console.log(res);
        setContent(res.data.result[0].description);
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };
  useEffect(() => {
    getStaticContent();
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      {sessionStorage.getItem("userToken") ? (
        <Container maxWidth={"lg"} py={4}>
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            sx={{ fontSize: { md: "45px", xs: "30px" }, fontWeight: "600" }}
          >
            About Us
          </Typography>
          <Grid container mt={2} justifyContent="space-between">
            <Grid item lg={5}>
              <Box
                component="img"
                sx={{ width: { xs: "200px", md: "400px", xl: "530px" } }}
                src={"/images/about (2).png"}
              />
            </Grid>
            <Grid item lg={6}>
              <Box my={1}>
                <Typography
                  variant="body1"
                  color={currentTheme == "DARK" ? "fff" : "000"}
                  sx={{
                    fontWeight: "300",
                    marginTop: "8px  ",
                    textAlign: "start",
                    textAlign: "justify",
                  }}
                >
                  <Box
                    className="wordBreak"
                    style={{
                      color: currentTheme === "DARK" ? "#fff" : "#000",
                      wordBreak: "break-all",
                    }}
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: `${content}`,
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Box
          sx={{
            paddingX: { md: "70px", xs: "30px" },
          }}
          py={4}
        >
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            sx={{ fontSize: { md: "45px", xs: "30px" }, fontWeight: "600" }}
          >
            About Us
          </Typography>
          <Grid container mt={2} justifyContent="space-between">
            <Grid item lg={5}>
              <Box
                component="img"
                sx={{ width: { xs: "200px", md: "400px", xl: "530px" } }}
                src={"/images/about (2).png"}
              />
            </Grid>
            <Grid item lg={7}>
              <Box my={1}>
                <Typography
                  variant="body1"
                  color={currentTheme == "DARK" ? "fff" : "000"}
                  sx={{
                    fontWeight: "300",
                    marginTop: "8px  ",
                    textAlign: "start",
                    textAlign: "justify",
                  }}
                >
                  <Box
                    className="wordBreak"
                    style={{
                      color: currentTheme === "DARK" ? "#fff" : "#000",
                      //wordBreak: "break-all",
                      textOverflow: "break-word",
                    }}
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: `${content}`,
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default AboutUs;
