import React, { useEffect, useRef, useState } from "react";
import {
  AccountCircleOutlined,
  ArrowBack,
  AttachFile,
  KeyboardVoice,
  PhotoCamera,
  Search,
  Send,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import "./masterMindCommunity.scss";
import customAxios from "../../utils/customAxios";
import { ApiConfig, socketUrl } from "../../services/ApiConfig";
import { useNavigate, useParams } from "react-router";
import { Container } from "@mui/system";
import io from "socket.io-client";
import { socketEvent } from "../guestUser/guestSocketConfig/guestSocket";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import Webcam from "react-webcam";
import ScheduleCallPopUp from "../../components/scheduleAction/ScheduleCallPopUp";
import VoiceInputButton from "./VoiceInputButton";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const chats = [
  "Types of Business Growth",
  "Business Expansion",
  "Types of Business Growth",
  "Business Expansion",
  "Types of Business Growth",
  "Business Expansion",
  "Types of Business Growth",
];

export default function MasterMindCommunity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentChat, setCurrentChat] = useState(
    sessionStorage.getItem("currentChat") || 0
  );
  const [topicFilter, setTopicFilter] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [currentTopic, setCurrentTopic] = useState(
    {} || sessionStorage.getItem(currentTopic)
  );
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isConnected, setIsConnected] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [scheduleModal, setScheduleModal] = useState(false);
  // const [myUniqueId, setmyUniqueId] = useState(localStorage.getItem("userId"));
  const [roomId, setRoomId] = useState("");
  const [message, setMessage] = useState("");
  const [BOList, setBOList] = useState("");
  const webcamRef = React.useRef(null);
  const [updateScheduleCallList, setUpdateScheduleCallList] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [currentBO, setCurrentBO] = useState("");
  const chatScreen = useRef();
  const [isListening, setIsListening] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const socketRef = useRef(null);
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        secure: true,
        reconnect: true,
      });
    }

    return () => {
      // Clean up socket when component unmounts
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);
  const userId = sessionStorage.getItem("userId");
  const userUniqueId = sessionStorage.getItem("userUniqueId");
  console.log(userId, "userIDDD");
  useEffect(() => {
    console.log(message, "message");
    getInitialTopics();
    getInitialBOList();

    return () => {
      disconnectSocket();
      if (socketRef.current) {
        const roomData = {
          userId: userId,
        };
        console.log(roomData, "roomDataa");
        socketRef.current.disconnect(roomData);
      }
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      handleInitialSocketConnect();
    }
  }, [currentTopic]);

  useEffect(() => {
    if (isConnected) {
      receiveMessageHandler();
      userMessageHandler();
    }
  }, [isConnected]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on("connect", () => setIsConnected(true));
      return () => {
        socketRef?.current?.off("connect");
        socketRef?.current?.off(socketEvent.initiateChat);
      };
    }
  }, [roomId]);

  const handleChatData = () => {
    if (socketRef.current) {
      socketRef.current.on("chatInitiated", (data) => {
        // console.log();
        setMessageList([]);
        setMessageList(data?.messages);
        setRoomId(data?.chatRoomId);
        setInitialLoading(false);
      });
    }
  };
  const getInitialTopics = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getCohortWithTopicListing,
        params: {
          cohort_id: id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      console.log("getCohortWithTopicListing-=-=-", res);
      if (!res?.data) {
        return;
      }
      setTopicList(res?.data?.result[0]?.cohortTopics || []);

      const storedRoomId = sessionStorage.getItem("roomId");
      if (storedRoomId) {
        setCurrentTopic(JSON.parse(storedRoomId));
      } else if (res?.data?.result[0]?.cohortTopics?.length > 0) {
        setCurrentTopic(res?.data?.result[0]?.cohortTopics[0]);
      }
    } catch (err) {
      console.log(err, "Error occurred while fetching topics");
    }
  };

  // const getInitialTopics = async () => {
  //   try {
  //     const res = await customAxios({
  //       method: "GET",
  //       url: ApiConfig.getCohortWithTopicListing,
  //       params: {
  //         cohort_id: id,
  //       },
  //       headers: {
  //         token: sessionStorage.getItem("userToken"),
  //       },
  //     });
  //     console.log("getCohortWithTopicListing-=-=-", res);
  //     if (!res?.data) {
  //       return;
  //     }
  //     setTopicList(res?.data?.result[0]?.cohortTopics || []);
  //     // if (res?.data?.result[0]?.cohortTopics?.length > 0) {
  //     //   sessionStorage.getItem("roomId")
  //     //     ? setCurrentTopic(JSON.parse(roomId))
  //     //     : setCurrentTopic(res?.data?.result[0]?.cohortTopics[0]);
  //     // }
  //     if (res?.data?.result[0]?.cohortTopics?.length > 0) {
  //       sessionStorage.getItem("roomId")
  //         ? setCurrentTopic(JSON.parse(roomId))
  //         : setCurrentTopic(res?.data?.result[0]?.cohortTopics[0]);
  //     }
  //   } catch (err) {
  //     console.log(err, "hfsf");
  //   }
  // };
  const getInitialBOList = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllBOList,
        params: {
          cohort_id: id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      console.log("getCohortWithTopicListing-=-=-", res);
      if (!res?.data) {
        return;
      }
      setBOList(res?.data?.result?.BOs || []);
      if (res?.data?.result?.BOs.length > 0) {
        setCurrentBO(res?.data?.result?.BOs);
      }
    } catch (err) {
      console.log(err, "hfsf");
    }
  };
  const handleInitialSocketConnect = () => {
    if (socketRef.current) {
      const roomData = {
        userId: userId,
        RoomId: currentTopic?.id,
        userOnlineFlag: true,
      };

      socketRef.current.emit(socketEvent.initiateChat, roomData);
      socketRef.current.emit("onlineStatusChanged", (data) => {
        console.log(data, "onlineStatusChanged");
      });
      handleChatData();
    }
  };
  // const receiveMessageHandler = () => {
  //   console.log("recieve");
  //   if (socketRef.current) {
  //     setIsLoading(true)
  //     socketRef.current.on("receiveMessage", (data) => {
  //       setMessageList((p) => [...p, data]);

  //       // chatScreen
  //       // window.scrollTo(0, chatScreen.current.body.scrollHeight);
  //     });
  //     setIsLoading(false)
  //     socketRef.current.on("userMessage", (data) => {
  //       setMessageList((p) => [...p, data]);
  //       // chatScreen
  //       // window.scrollTo(0, chatScreen.current.body.scrollHeight);
  //     });
  //   }
  // };
  const receiveMessageHandler = () => {
    console.log("receive");
    if (socketRef.current) {
      setIsLoading(true); // Set loading to true when waiting for messages
      socketRef.current.on("receiveMessage", (data) => {
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });
      socketRef.current.on("userMessage", (data) => {
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });

      // Set loading to false once all messages have been received
      socketRef.current.on("receiveMessage", () => setIsLoading(false));
      socketRef.current.on("userMessage", () => setIsLoading(false));
    }
  };

  const sendMessageHandler = async () => {
    setShowEmojiPicker(false);
    try {
      if (socketRef.current) {
        // Check if socket is initialized
        socketRef.current.emit(socketEvent.sendMessage, {
          from: userId,
          user_unique_id: userUniqueId,
          RoomId: roomId,
          message: message?.trim(),
        });
        setMessage("");
        console.log(sessionStorage.getItem("currentChat"));
      }
    } catch (error) {
      console.log(error, "Error sending message");
    }
  };

  const userMessageHandler = async () => {
    try {
      if (socketRef.current) {
        socketRef.current.emit(socketEvent.userMessage, {
          from: userId,
          RoomId: roomId,
          message: message?.trim(),
        });
        setMessage("");
      }
    } catch (error) {
      console.log(error, "Error sending message");
    }
  };

  const handleFileSelect = (files) => {
    if (files.length > 0) {
      const selectedFile = files[0];

      console.log("Selected file:", selectedFile);
    }
  };
  const disconnectSocket = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.disConnectSocket,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleCapturePhoto = () => {
  //   console.log("Button clicked");
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   console.log(imageSrc, "imageSrc");
  //   setCapturedImage(imageSrc);
  //   setShowWebcam(false);
  // };
  const handleEmojiClick = (event, emojiObject) => {
    console.log(event, emojiObject, "aasasasas");
    if (event?.emoji) {
      setMessage((prevMessage) => prevMessage + event.emoji);
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };
  const handleVoiceInput = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      console.log("Voice recognition started.");
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Transcript:", transcript);
      setMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Voice recognition ended.");
      setIsListening(false);
    };

    recognition.start();
  };

  // useEffect(() => {
  //   // Get the last message element
  //   const lastMessage = chatScreen?.current?.lastChild;
  //   if (lastMessage) {
  //     // Scroll the last message into view
  //     lastMessage.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [messageList]);
  useEffect(() => {
    // Get the chat container element
    const chatContainer = chatScreen?.current;
    if (chatContainer) {
      // Get the last message element inside the chat container
      const lastMessage = chatContainer.lastChild;
      if (lastMessage) {
        // Scroll the last message into view within the chat container
        lastMessage.scrollIntoView({ block: "end" });
      }
    }
  }, [messageList]);

  return (
    <Box style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}>
      <Container maxWidth="lg">
        <div
          className="masterMindCommunityContainer"
          style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}
        >
          <div
            className="header"
            style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              />
            </IconButton>
            <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
              Master Mind Community
            </h2>
          </div>

          <div className="chatContainer">
            <Box
              component={OverlayScrollbarsComponent}
              className="chatList scrollBarCustomColor"
              style={{
                background: currentTheme == "DARK" ? "#000" : "#fff",
                height: "70vh",
                overflow: "scroll",
              }}
            >
              <div className="header">
                <h3 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                  Topics
                </h3>
                <TextField
                  value={topicFilter}
                  onChange={(e) => {
                    setTopicFilter(e?.target?.value);
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FCFCFC",
                    padding: "0",

                    background: currentTheme == "DARK" ? "#151515" : "#FCFCFC",
                  }}
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Search
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      padding: "0 5px",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    },
                  }}
                />
              </div>
              <div
                className="chats"
                style={{
                  background: currentTheme == "DARK" ? "#000" : "#f8f8f8",
                }}
              >
                {topicList &&
                  topicList?.length > 0 &&
                  topicList
                    ?.filter((e) => e?.topicName?.includes(topicFilter))
                    ?.map((topic, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            background:
                              currentChat !== i && currentTheme == "DARK"
                                ? "#000"
                                : currentChat == i
                                ? "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)"
                                : "#fff",
                            color: currentChat == i && "#fff",
                          }}
                          onClick={() => {
                            setCurrentChat(i);
                            setCurrentTopic(topic);
                            const serializedTopic = JSON.stringify(topic);
                            sessionStorage.setItem("roomId", serializedTopic);
                            console.log(serializedTopic);
                            setRoomId(serializedTopic);
                            console.log(topic, "hjhjhjk");
                            sessionStorage.setItem(
                              "currentTopic",
                              topic?.topicName
                            );
                            sessionStorage.setItem("currentChat", i);
                          }}
                        >
                          <span
                            style={{
                              background:
                                currentChat === i
                                  ? "#fff"
                                  : sessionStorage.getItem("currentChat") === i
                                  ? "#fff"
                                  : "#fff",
                              color:
                                currentChat === i && currentTheme == "DARK"
                                  ? "#000"
                                  : currentTheme == "DARK"
                                  ? "#000"
                                  : "#000",
                            }}
                          >
                            {topic?.topicName[0].toUpperCase()}
                          </span>
                          <p
                            style={{
                              color:
                                currentChat === i ||
                                currentTheme == "DARK" ||
                                sessionStorage.getItem("currentChat") == i
                                  ? "#fff"
                                  : currentChat === i ||
                                    sessionStorage.getItem("currentChat") == i
                                  ? "#fff"
                                  : "#000",
                            }}
                            className="ellipsis"
                          >
                            {topic?.topicName}
                          </p>
                        </div>
                      );
                    })}
              </div>
            </Box>
            {/* {initialLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : ( */}
            <div className="chatScreen">
              {initialLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div
                  className="header"
                  style={{
                    background: currentTheme == "DARK" ? "#000" : "#fff",
                  }}
                >
                  {/* <span></span> */}
                  <p
                    style={{
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    Community Chat
                  </p>
                </div>
              )}
              <div
                style={{
                  overflow: "scroll",
                  height: "50vh",
                  position: "relative",
                }}
              >
                {" "}
                <div className="chatDetails" ref={chatScreen}>
                  <div
                    style={{
                      position: "absolute",
                      top: "40%",
                      textAlign: "center",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    {messageList.length <= 0
                      ? "There are currently no messages in this topic. Feel free to start the conversation!"
                      : ""}
                  </div>

                  {messageList?.map((data, i) => {
                    {
                      console.log(data, "ssssssssssss");
                    }

                    const isSentByUser = data.from === userId;
                    return (
                      <div
                        key={i}
                        className={`chatBubble ${
                          isSentByUser ? "chatBubbleSelf" : "chatBubbleOther"
                        }`}
                      >
                        <div>
                          <span
                            style={{
                              color: "#c25208",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {data?.user_unique_id}
                          </span>
                        </div>
                        <div className="messageContent">{data?.message}</div>

                        <span className="bubbleChatDate">
                          {data?.createdAt
                            ? dayjs(data?.createdAt)?.format("lll")
                            : "NA"}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="chatKeyboard"
                style={{
                  background: currentTheme == "DARK" ? "#000" : "#fff",
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileSelect(e.target.files)}
                />
                {/* <AttachFile
                onClick={() => document.getElementById("fileInput").click()}
                style={{
                  color: "#909090",
                  margin: "0 25px 0 25px",
                  rotate: "30deg",
                  color: currentTheme === "DARK" ? "#fff" : "#909090",
                  cursor: "pointer",
                }}
              /> */}
                {/* <PhotoCamera
                style={{
                  color: "#909090",
                  margin: "0 23px 0 0",
                  color: currentTheme === "DARK" ? "#fff" : "#909090",
                  cursor: "pointer",
                }}
                onClick={() => setShowWebcam(true)}
              />

              {showWebcam && (
                <div>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    height={"100%"}
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCapturePhoto();
                    }}
                  >
                    Capture
                  </Button>
                </div>
              )} */}

                {/* {capturedImage && <img src={capturedImage} alt="Captured" />} */}

                <div
                  className="keypad"
                  style={{
                    background: currentTheme == "DARK" ? "#000" : "#fff",
                  }}
                >
                  <input
                    placeholder="Type a message..."
                    value={message}
                    style={{
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                    onChange={(e) => {
                      setMessage(e?.target?.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendMessageHandler();
                      }
                    }}
                  />
                  <KeyboardVoice
                    onClick={handleVoiceInput}
                    className={isListening ? "listening" : ""}
                    style={{
                      margin: "0 25px 0 0",
                      color: currentTheme == "DARK" ? "#fff" : "#909090",
                      cursor: "pointer",
                    }}
                  />
                  {/* <VoiceInputButton onVoiceInput={handleVoiceInput} /> */}
                  <SentimentSatisfiedAlt
                    style={{
                      color: currentTheme === "DARK" ? "#fff" : "#909090",
                      margin: "0 29px 0 0",
                      cursor: "pointer",
                    }}
                    onClick={toggleEmojiPicker}
                  />
                  <div>
                    <IconButton
                      onClick={sendMessageHandler}
                      disabled={!message}
                    >
                      <Send />
                    </IconButton>
                  </div>
                </div>
                {showEmojiPicker && (
                  <EmojiPicker
                    style={{
                      position: " absolute",
                      bottom: "83px",
                      left: "220px",
                    }}
                    onEmojiClick={handleEmojiClick}
                    disableSearchBar
                    disableSkinTonePicker
                    groupVisibility={{ recently_used: false }}
                  />
                )}
              </div>
            </div>
            {/* )} */}
            {/* <div className="chatKeyboard">
            <AttachFile
              style={{
                color: "#909090",
                margin: "0 25px 0 25px",
                rotate: "30deg",
              }}
            />
            <PhotoCamera style={{ color: "#909090", margin: "0 23px 0 0" }} />
            <div className="keypad">
              <input placeholder="Type a message..." />
              <KeyboardVoice
                style={{ color: "#909090", margin: "0 25px 0 0" }}
              />
              <SentimentSatisfiedAlt
                style={{ color: "#909090", margin: "0 29px 0 0" }}
              />
              <div>
                <Send />
              </div>
            </div>
          </div> */}

            <div
              className="stasutScreen"
              style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
            >
              <Box
                className="header"
                component={OverlayScrollbarsComponent}
                style={{
                  background: currentTheme == "DARK" ? "#000" : "#fff",
                  overflow: "scroll",
                  height: "70vh",
                }}
              >
                {/* <div
                  className="inner"
                  style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
                >
                  <AccountCircleOutlined style={{ fontSize: "3em" }} />
                  <div>
                    <h3 className="ellipsis"
                      style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    >
                      {sessionStorage.getItem("advisorName")}
                    </h3>
                    <p
                      style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    >
                      Business Advisor
                    </p>
                  </div>
                </div>
                <button
                  style={{
                    background: currentTheme == "DARK" ? "#151515" : "#fff",
                  }}
                >
                  <img src="/images/zoom.png" alt="" />
                  <span
                    style={{
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                      // color: "#0B5CFF",
                      fontSize: "16px",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => setScheduleModal(true)}
                  >
                    Zoom with Advisor
                  </span>
                </button> */}
                <div
                  className="listing"
                  style={{
                    background: currentTheme == "DARK" ? "#151515" : "#fff",
                    marginTop: "10px",
                  }}
                >
                  <h4
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >{`${BOList.length} Participants`}</h4>
                  {BOList &&
                    BOList?.length > 0 &&
                    BOList.map((topic, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          // setCurrentChat(i);
                          setCurrentBO(id);
                        }}
                        style={{
                          height: "65px",
                          // background: i % 2 === 0 ? "#FFFFF" : "#FAFAFA",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            background:
                              currentTheme == "DARK" ? "#151515" : "#fff",
                            background:
                              currentTheme == "LIGHT" &&
                              currentChat === i &&
                              "#fff",
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          B
                        </span>

                        {topic?.user_unique_id ? (
                          <p
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >{`${topic.user_unique_id.slice(0, 5)}`}</p>
                        ) : (
                          <p>NA</p>
                        )}
                        <div
                          style={{
                            border: "1px solid #C9C9C9",
                            borderRadius: "50%",
                            background: topic?.userOnlineFlag
                              ? "#33F247"
                              : "transparent",
                            width: "10px",
                            height: "20px",
                          }}
                        ></div>
                      </div>
                    ))}
                </div>
              </Box>
            </div>
          </div>

          <ScheduleCallPopUp
            setOpen={setScheduleModal}
            open={scheduleModal}
            isCallListUpdated={updateScheduleCallList}
            setIsCallListUpdated={setUpdateScheduleCallList}
          />
        </div>
      </Container>
    </Box>
  );
}
