import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import CookieModal from "../../components/CookieModal";

import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";

const DataComponent = ({ heading, type }) => {
  const navigator = useNavigate();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleOpenModal = () => {
    // Handle successful password reset logic
    type == true ? setModalOpen(true) : setModalOpen(false);
    console.log(type);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const [content, setContent] = useState("");
  const getStaticContent = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.findStaticContentByType,
        data: {
          content_type: heading,
          // heading === "Terms and Conditions" ? "termsAndCondition" ? "Privacy Policy":"privacyPolicy"
        },
      });
      if (res.data?.statusCode == 200) {
        setTitle(res.data.result[0].content_title);
        setContent(res.data.result[0].description);
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };

  useEffect(() => {
    getStaticContent();
    handleOpenModal();
  }, [location]);

  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <Box
      sx={{
        paddingX: {
          md: "60px",
          xs: "30px",
          backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
        },
      }}
      py={4}
    >
      <Grid item lg={6}>
        <Typography
          sx={{
            fontSize: "45px",
            fontWeight: "600",
            color: currentTheme == "DARK" ? "#fff" : "#000",
          }}
        >
          {title}
        </Typography>

        <Box my={3}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "300", marginTop: "8px  ", textAlign: "justify" }}
          >
            <Box
              style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
              component="div"
              dangerouslySetInnerHTML={{
                __html: `${content}`,
              }}
            />
          </Typography>
        </Box>
      </Grid>
{/* 
      <CookieModal open={isModalOpen} onClose={handleCloseModal} /> */}
    </Box>
  );
};

export default DataComponent;
