import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Typography,
  Box,
  List,
  Divider,
  ListItemText,
  ListItem,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CancelIcon from "@mui/icons-material/Cancel";
// import {
//   deleteApiHandlerWithToken,
//   getApiHandlerWithToken,
// } from "../config/service";
import dayjs from "dayjs";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
// import toast from "react-hot-toast";
// import CircularProgressBox from "./CircularProgressBox";
// import NoDataFound from "./NoDataFound";
const NotificationModal = ({ open, setOpen, setFlag, flag }) => {
  const [notificationList, setNotificationList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isListUpdated, setisListUpdated] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const getAllNotification = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.findAllNotification,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        setIsDataLoading(false);
        setNotificationList(res?.data?.result || []);
        setFlag(!flag);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteNotification = async (data) => {
    try {
      const res = await customAxios({
        method: "DELETE",
        url: ApiConfig.deleteNotification,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          notification_id: data?.id,
        },
      });
      if (res.data.statusCode === 200) {
        setisListUpdated((e) => !e);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNotification();
  }, [isListUpdated]);

  //   const getAllNotification = async () => {
  //     setIsDataLoading(true);
  //     const res = await getApiHandlerWithToken("findAllNotification");
  //     console.log(res, "ajgsfaf");
  //     if (!res) {
  //       return;
  //     }
  //     setIsDataLoading(false);
  //     setNotificationList(res?.result || []);
  //   };

  //   const removeParticularNotification = async (data) => {
  //     setIsDataLoading(true);
  //     const res = await deleteApiHandlerWithToken("deleteNotification", {
  //       notification_id: data?.id,
  //     });
  //     setIsDataLoading(false);
  //     if (!res) {
  //       return;
  //     }
  //     setisListUpdated((e) => !e);
  //     toast.success(res?.result || "Notification updated successfully.");
  //   };

  //   const clearAllNotification = async () => {
  //     setIsDataLoading(true);
  //     const res = await deleteApiHandlerWithToken("clearAllNotification");
  //     if (!res) {
  //       return;
  //     }
  //     toast.success(res?.result || "Notification deleted successfully.");
  //     setisListUpdated((e) => !e);
  //     setIsDataLoading(false);
  //   };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: { xs: "static", sm: "absolute" },
              top: "70px",
              right: "140px",
              minHeight: "500px",
              minWidth: "450px",
              maxWidth: "450px",
              overflow: "scroll",
              bgcolor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                // px: 2,
              }}
            >
              {/* <IconButton
                  sx={{ position: "absolute", right: "-18px", top: "-18px" }}
                >
                  <CancelIcon />
                </IconButton> */}
              <Typography variant="h6">Notifications</Typography>
              {/* <Button
                  variant="text"
                  sx={{ textTransform: "none", color: "#523168" }}
                  onClick={() => {
                    clearAllNotification();
                  }}
                >
                  Clear All
                </Button> */}
            </Box>
            <Divider />
            <List
              component={OverlayScrollbarsComponent}
              className="scrollBarCustomColor"
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              {isDataLoading ? (
                <>{/* <CircularProgressBox /> */}</>
              ) : notificationList?.length == 0 ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="400px"
                >
                  {"No notifications found."}{" "}
                </Box>
              ) : (
                notificationList?.map((data, i) => {
                  return (
                    <ListItem
                      key={i}
                      sx={{
                        bgcolor: "#f8f8f8",
                        borderRadius: "8px",
                        mb: 1,
                      }}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            deleteNotification(data);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={data?.description ? data?.description : "NA"}
                        secondary={
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Typography variant="subtitle2">
                              {data?.createdAt
                                ? dayjs(data?.createdAt)?.format("lll")
                                : "NA"}
                              {/* Friday, Nov. 13 2023 at 10:30 AM */}
                            </Typography>
                            {/* <Typography variant="subtitle2">
                              60 mins ago
                            </Typography> */}
                          </Box>
                        }
                      />
                    </ListItem>
                  );
                })
              )}
            </List>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default NotificationModal;
