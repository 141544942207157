import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
  FormHelperText,
  useTheme,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Testimonials from "./Testimonials";
import { useNavigate } from "react-router-dom";
import "./guestUser.scss";
// import "./guestUserDark.scss";
import { East } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useMediaQuery } from "@mui/material";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import customAxios from "../../utils/customAxios";
import { useDispatch, useSelector } from "react-redux";
import { ThemeMode, setTheme } from "../../redux/features/themeSwitcher";
import styled from "@emotion/styled";

const MainStyledComponent = styled("Box")(({ theme }) => ({
  "& .subscriptionTextField": {
    "& .subscriptionBtn": {
      display: "flex",
      justifyContent: "end",
    },
    "& .textFieldCls": {
      width: "512px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    "& .subscriptionSide": {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center !important",
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },
  },
}));
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3.5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required.")
    .matches(
      /^[a-zA-Z0-9._%+$-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    )
    .min(8, "Email address must be at least 8 characters."),
});

// .max(79, "Email address must not exceed 80 characters.")

const Guestuserhome = () => {
  const textToBackgroundMap = {
    1: "#5231681A",
    2: "rgba(255, 188, 109, 0.10)",
  };
  const token = sessionStorage.getItem("userToken");
  const [allCollaborativePartners, setAllCollaborativePartners] = useState([]);
  const [allCollaborativeProjects, setAllCollaborativeProjects] = useState([]);
  const [allBannerMaster, setAllBannerMaster] = useState([]);
  const [allKeyAndFeatures, setAllKeyAndFeatures] = useState([]);
  const [allTestimonialsData, setAllTestimonialsData] = useState([]);
  const [subscribe, setSubscribe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [allPartnersBlocked, setAllPartnersBlocked] = useState(false);
  const [allProjectsBlocked, setAllProjectsBlocked] = useState(false);
  const matchesForSliderXl = useMediaQuery((theme) =>
    theme.breakpoints.up("xl")
  );
  const matchesForSlider = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const matches = useMediaQuery("(min-width:600px)");
  const matchesBig = useMediaQuery("(max-width:1130px)");
  const matchesMed = useMediaQuery("(max-width:862px)");
  // const matchesXs = useMediaQuery("(max-width:600px)");
  const matchesXxs = useMediaQuery("(max-width:600px)");
  const matchesXxss = useMediaQuery("(max-width:500px)");
  const navigate = useNavigate();

  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  console.log(currentTheme);

  useEffect(() => {
    console.log(currentTheme, "alnksfkaugfu");
  }, [currentTheme]);

  const getAllBannerMaster = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllBannerMaster,
      });
      console.log("dataa", res?.data?.result);
      if (res.data.statusCode === 200) {
        setAllBannerMaster(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllKeyAndFeatures = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllKeyAndFeatures,
      });
      console.log("dataa1", res?.data?.result?.allKeyAndFeatures);
      if (res.data.statusCode === 200) {
        setAllKeyAndFeatures(res?.data?.result?.allKeyAndFeatures);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllGuestUserDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllGuestUserDetails,
      });
      console.log(res);
      if (res.data.statusCode === 200) {
        setAllCollaborativePartners(res?.data?.result?.CollaborativePartners);
        setAllCollaborativeProjects(res?.data?.result?.CollaborativeProjects);
        setAllTestimonialsData(res?.data?.result?.Testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getAllBannerMaster();
    getAllKeyAndFeatures();
    getAllGuestUserDetails();
  }, []);
  useEffect(() => {
    const areAllBlocked = allCollaborativeProjects.every(
      (item) => item.status === "BLOCKED"
    );

    setAllProjectsBlocked(areAllBlocked);
  }, [allCollaborativeProjects]);
  useEffect(() => {
    const areAllBlocked = allCollaborativePartners.every(
      (item) => item.status === "BLOCKED"
    );

    setAllPartnersBlocked(areAllBlocked);
  }, [allCollaborativePartners]);

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);

  const handleSubscribe = async (values) => {
    setLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.subscribe,
        data: {
          email: values.email,
        },
      });
      if (res?.data?.statusCode === 200) {
        // toast.success("Subscribed successfully");
        setLoading(false);
        setSubscribed(true);
        setErrorMessage(
          "Thank you for subscribing! You are now part of our community. Get ready to receive our latest updates, offers, and exclusive content"
        );
      } else {
        setLoading(false);
        setSubscribed(false);
        setErrorMessage(res.data?.responseMessage || "Something Went Wrong.");
      }
    } catch (error) {
      console.log(error);
      // toast.error(error?.response?.responseMessage)
      if (error?.response?.status === 409)
        setErrorMessage(error?.response?.data?.responseMessage);
      console.log(error, "mabjsfadf");
      // toast.success(error?.response?.data?.responseMessage);
      setLoading(false);
      setSubscribed(false);
    }
  };

  return (
    <MainStyledComponent>
      <div
        style={{
          backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
          marginTop: "32px",
        }}
      >
        {/* <Container maxWidth="lg"> */}
        <Carousel
          responsive={responsive}
          showDots={true}
          arrows={false}
          autoPlay={true}
          // infinite={true}
          // autoPlaySpeed={3000}
        >
          {allBannerMaster
            ?.filter((val) => val?.status === "ACTIVE")
            ?.map((val, i) => {
              return (
                <>
                  <div
                    className={"heroBannerContainer"}
                    key={i}
                    style={{
                      backgroundImage: `url(${val.image_url})`,
                    }}
                  >
                    <div style={{ userSelect: "none" }} className="inner1">
                      <div className="text-container">
                        <h2 className="wordBreak">
                          {val.title
                            ? val.title.length > 41
                              ? val.title.slice(0, 39) + "..."
                              : val.title > 30 && matchesXxs
                              ? val.title.slice(0, 27) + "..."
                              : val.title
                            : "N/A"}
                        </h2>
                        <p className="wordBreak" style={{fontWeight: 'bold', color: 'white'}}>{val.description}</p>
                      </div>
                      <a
                        href={`${val.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <button>
                          <span>Learn more</span>
                          <East />
                        </button>
                      </a>
                    </div>
                    {/*<div className="inner2">
                      <div>
                        <p>Data points used for analytics</p>
                      </div>
                      <div>
                        <p>Investable assets predicted per year</p>
                      </div>
                      <div>
                        <p>Predicted home value transacted per year</p>
                      </div>
                    </div>*/}
                  </div>
                </>
              );
            })}
        </Carousel>
        {/* </Container> */}

        {allCollaborativePartners?.length > 0 && !allPartnersBlocked ? (
          <div
            style={{
              backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: matches ? "75%" : "90%",
                padding: "2rem 0 1rem 0",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "2.5em", xs: "1.5em" },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontWeight={600}
              >
                Collaborative Partners
              </Typography>
              <Typography
                my={2}
                variant="h5"
                fontSize="18px"
                color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
              >
                Business BOOST! Society is proud to collaborate with
                industry-leading partners who share our vision for innovation
                and excellence. These strategic partnerships allow us to combine
                our expertise and resources to deliver exceptional solutions to
                our clients. Here are some of our valued collaborative partners.
              </Typography>
            </Box>
            <div className="collaborativePartnerContainer">
              <Swiper
                modules={[Navigation]}
                slidesPerView={
                  matchesForSliderXl
                    ? 3.5
                    : matchesForSlider
                    ? 3
                    : matches
                    ? 2.5
                    : 1
                }
                navigation={true}
                pagination={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                style={{
                  "--swiper-pagination-color": "#fff",
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "25px",
                }}
              >
                {allCollaborativePartners.map(
                  (val, idx) =>
                    val.status === "ACTIVE" && (
                      <SwiperSlide key={idx}>
                        <div
                          className="collaborativePartnerBox"
                          style={{
                            background:
                              idx % 2 === 0
                                ? "rgba(82, 49, 104, 0.10)"
                                : "rgba(255, 188, 109, 0.10)",
                            userSelect: "none",
                            cursor: "pointer",

                            position: "relative",
                            paddingBottom: "40px" // Add padding to the bottom to avoid overlap with the "Learn more" text
                          }}
                        >
                          <Box
                            component="img"
                            src={val?.image_url}
                            style={{ height: "5vw" }}
                          />
                          <Typography
                            variant="h5"
                            color={currentTheme == "DARK" ? "#fff" : "#000"}
                            sx={{ fontSize: { md: "1.5rem", xs: "1rem" } }}
                            mt={1}
                            fontWeight={700}
                            className="ellipsis"
                          >
                            {val?.title}
                          </Typography>
                          <Typography
                            my={1}
                            color={
                              currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"
                            }
                            className="wordBreak"
                            sx={{
                              overflow: "hidden",
                              fontSize: { lg: "15px", xs: "13px" },
                              textAlign: "justify",
                            }}
                          >
                            {val?.description}
                          </Typography>
                          <img
                            src="/images/bottomleftvector.png"
                            className="vextorImage"
                            alt=""
                          />

                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent={"flex-start"}
                          >
                            <Box
                              m={2}
                              display="flex"
                              direction="row"
                              alignItems="center"
                            >
                              <Typography
                                fontSize="22px"
                                sx={{
                                  color: "#542f03",
                                  cursor: "pointer",
                                  //marginLeft: "8px",

                                  position: "absolute",
                                  bottom: "23px", // Position from bottom
                                  left: "40px",  // Position from left
                                  margin: 0 // Reset margin
                                }}
                                onClick={() => {
                                  window.open(val.website_url);
                                }}
                              >
                                Learn more &rarr;{"  "}
                                <Box
                                  mx={0.5}
                                  pt={1}
                                  component="img"
                                  //src="/images/goldenarrow.png"
                                ></Box>
                              </Typography>
                            </Box>
                          </Grid>
                        </div>
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            </div>
          </div>
        ) : (
          ""
        )}

        {allCollaborativeProjects?.length > 0 && !allProjectsBlocked ? (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              px={{ md: 30, xs: 4 }}
              mt={4.3}
              style={{}}
            >
              <Typography
                color={currentTheme == "DARK" ? "#fff" : "#000"}
                fontSize={{ md: "42px", xs: "25px" }}
                fontWeight={600}
              >
                Collaborative Projects
              </Typography>
              <Typography
                variant="h5"
                textAlign="center"
                color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
                mt={2}
                mb={1.2}
                sx={{
                  fontSize: "18px",
                }}
              >
                Our collaborative projects have been at the forefront of
                innovation, solving complex challenges and achieving remarkable
                outcomes. Here are some notable projects we have undertaken in
                collaboration with our partners
              </Typography>
            </Box>
            <div className="collaborativeProjectContainer">
              <img
                src="/images/projectvector.png"
                className="bgVector"
                alt=""
              />

              <Swiper
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={
                  matchesXxss
                    ? 1
                    : matchesForSliderXl
                    ? 3.5
                    : matchesForSlider
                    ? 3
                    : matches
                    ? 2
                    : matchesXxs
                    ? 1.5
                    : 1
                }
                navigation={true}
                pagination={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                style={{
                  "--swiper-pagination-color": "#fff",
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "25px",
                }}
              >
                {allCollaborativeProjects.map(
                  (val, idx) =>
                    val.status === "ACTIVE" && (
                      <SwiperSlide key={idx}>
                        <div
                          style={{
                            userSelect: "none",
                            cursor: "pointer",
                            padding: "20px 0px 0px 0px ",
                          }}
                          className="collaborativeProjectWrapper"
                        >
                          <div className="img-wrapper">
                            <img
                              className="hero-img"
                              src={val.image_url}
                              alt="img"
                            />
                          </div>
                          <Typography
                            className="wordBreak"
                            variant="subtitle1"
                            sx={{
                              color: "rgba(255, 255, 255, 0.75)",
                              marginTop: "10px",
                              textAlign: "justify",
                              paddingX: "20px",
                              fontSize: { xs: "15px", md: "16px" },
                            }}
                          >
                            {val.description}
                          </Typography>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent={"flex-start"}
                          >
                            <Box
                              m={2}
                              display="flex"
                              direction="row"
                              alignItems="center"
                            >
                              <Typography
                                fontSize="22px"
                                sx={{
                                  color: "#FFBC6D",
                                  cursor: "pointer",
                                  marginLeft: "8px",
                                }}
                                onClick={() => {
                                  window.open(val.website_url);
                                }}
                              >
                                Learn more{" "}
                                <Box
                                  mx={0.5}
                                  pt={1}
                                  component="img"
                                  src="/images/goldenarrow.png"
                                ></Box>
                              </Typography>
                            </Box>
                          </Grid>
                        </div>
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            </div>
          </>
        ) : (
          ""
        )}

        <Grid
          container
          xs={12}
          spacing={1}
          sx={{
            paddingX: { lg: "60px", xs: "50px", md: "50px" },

            justifyContent: "center",
            display: "flex",
            backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
          }}
          pt={{ md: 11, xs: 2 }}
          px={{ md: 15, xs: 2 }}
          pb={{ md: 5, xs: 2 }}
        >
          <Grid
            item
            xl={5}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              "@media screen and (max-width: 1535px)": {
                paddingBottom: "15px",
              },
            }}
          >
            <Box style={{ marginLeft: "0px" }}>
              <Typography
                color="#FB3F10"
                fontFamily="'Alumni Sans', sans-serif"
                fontWeight={600}
                fontSize="20px"
                mt={{ md: 10, sm: 4, xs: 3 }}
                sx={{ color: currentTheme == "DARK" ? "#fff" : "#FB3F10" }}
              >
                Key Features & Benefits
              </Typography>
              <Typography
                color={currentTheme == "DARK" ? "#fff" : "#000"}
                fontSize="42px"
                fontWeight={600}
              >
                Unlock Potential: Partner with Us or Join a Cohort
              </Typography>
              <Typography
                my={2}
                fontSize="18px"
                fontWeight={400}
                color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
                // textAlign={"justify"}
              >
                Introducing the BoostSociety.ai portal - your gateway to advanced business and technical functionality. <strong>Partner with us </strong>
                to host your educational, community, and business programs on our platform, benefiting from dynamic, expert-led cohorts and innovative tools. Enhance learning with interactive courses, robust community features, and seamless communication. Track progress efficiently and gain valuable insights with our monitoring tools. Elevate your organization and unlock your programs' full potential with BoostSociety.ai. <strong>Interested in joining one of our cohorts?</strong> Explore our offerings and apply TODAY!
              </Typography>
              <Button
                my={2}
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  padding: "10px",
                  borderRadius: "5px",
                  fontSize: "16px",
                  paddingX: "20px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => navigate("/categories")}
              >
                View All Features
                <Box
                  mx={1}
                  mt={0.5}
                  component="img"
                  src="/images/whitearrow.png "
                />
              </Button>
            </Box>
          </Grid>

          <Grid
            container
            mt={1}
            xl={7}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "20px",
            }}
            spacing={2}
          >
            {allKeyAndFeatures
              ?.filter(val => val?.status === "ACTIVE")
              .slice(0, 4)
              .map((val, idx) => (
              <Grid
                item
                lg={4}
                md={5}
                sm={5}
                xs={12}
                position="relative"
                backgroundColor="#dadada"
                // width="100%"
                // maxWidth="300px"

                height="280px"
                borderRadius="20px"
                style={{
                  margin: "10px",
                  boxShadow:
                    "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)",
                }}
                py={4}
                px={2}
              >
                <Box
                  position="absolute"
                  top="0"
                  // left="200px"
                  right="0"
                  component="img"
                  src="/images/bordervector.png"
                />

                <Box
                  component="img"
                  sx={{ width: "42px", height: "35px" }}
                  src={val.image_url}
                />
                <Typography
                  fontSize={{ md: "24px", xs: "18px" }}
                  // mt={{ md: 6, xs: 0.5 }}
                  fontWeight={500}
                  // textAlign={"justify"}
                  mt={1}
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {val.title}
                </Typography>
                <Typography
                  color="#00000099"
                  fontSize={{ sm: "14px", xs: "12px" }}
                  fontWeight={400}
                  mt={1}
                  className="wordBreak"
                  sx={{
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",

                    height: "126px",
                  }}
                  // textAlign={"justify"}
                >
                  {val.description
                    ? val.description?.length > 219
                      ? val.description?.slice(0, 219) + "..."
                      : val.description
                    : "NA"}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
          }}
          className="howItWorksContainer"
        >
          <Box
            position="absolute"
            zIndex={10}
            left={{ lg: "75px", xs: "20px" }}
            top={{xl:"25px", lg: "25px", md: "25px", sm: "20px", xs: "5px"}}
          >
            <Typography
              sx={{
                color: "white",
                fontFamily: "'Alumni Sans', sans-serif",
                fontSize: "20px",
                fontWeight: 600,
                marginBottom: "-10px",
                //marginTop: "3rem",
                marginTop: {lg: "3rem", md: "2rem", sm:"1.5rem"}
              }}
            >
              Next Steps:
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "58px", md: "40px", sm: "30px", xs: "20px" },
                zIndex: "1",
                width: "60vw",
                fontWeight: "500",
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent", // Hide the actual text
              }}
            >
              How Does It Work
            </Typography>
            <Box
              display="flex"
              // flexDirection={{ xl: "row", xs: "column" }}
              justifyContent="space-between"
              width={{ xl: "45vw", md: "85vw", xs: "90vw" }}
            >
              <Box mx={{ md: 2, xs: 4 }}>
                <Typography
                  color="white"
                  sx={{
                    fontSize: { lg: "30px", md: "25px", sm: "20px", xs: "15px" },
                  }}
                  mb={0}
                  fontWeight={600}
                >
                  For Prospective Partners:
                </Typography>

                <Typography
                  color="white"
                  sx={{
                    fontSize: { lg: "18px", md: "16px", sm: "14px", xs: "13px" },
                  }}
                  pt={2}
                  fontWeight={500}
                  //textAlign={"justify"}
                  style={{ marginTop: '0px' }}
                  paddingTop={"0.5px"}
                >
                  <ol type="1">
                    <li>
                        Review the features and benefits on this page.
                    </li>
                    <li>
                        Email us with information regarding your interest in a strategic partnership. You can expect a follow-up within 48-72 business hours.
                    </li>
                    <li>
                        Join our vibrant community at BusinessBoostSociety.com to take advantage of personalized insights, expert advice, exclusive events, collaboration opportunities, and other resources.
                    </li>
                  </ol>
                </Typography>

                <Typography
                  color="white"
                  sx={{
                    fontSize: { lg: "30px", md: "25px", sm: "20px", xs: "15px" },
                  }}
                  pt={2}
                  fontWeight={500}
                  paddingTop={"0.5px"}
                >
                  For Students, Entrepreneurs, or Existing Business Owners Interested in a Cohort:
                </Typography>

                <Typography
                  color="white"
                  sx={{
                    fontSize: { lg: "18px", md: "16px", sm: "14px", xs: "13px" },
                  }}
                  pt={2}
                  fontWeight={500}
                  //textAlign={"justify"}
                  paddingTop={"0.5px"}
                >
                  <ol type="1">
                    <li>
                        Review the features and benefits on this page.
                    </li>
                    <li>
                        Browse our list of cohorts, select a cohort and apply! You can expect a decision according to the info included in the application details.
                    </li>
                    <li>
                        Join our vibrant community at BusinessBoostSociety.com to take advantage of personalized insights, expert advice, exclusive events, collaboration opportunities, and other resources.
                    </li>
                  </ol>
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>
        <Box
          style={{
            backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
          }}
          py={3}
        >
          <Testimonials testimonialData={allTestimonialsData} />
        </Box>
        <Grid
          position="relative"
          container
          sx={{
            // backgroundImage: { lg: 'url("/images/purplebg2.png")' },
            backgroundColor: "#523169",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          // mt={2}
          px={{ lg: 8, md: 6, xs: 4 }}
          py={{ xl: 6, xs: 2 }}
          alignItems="center"
        >
          <Grid item xs={12} lg={6}>
            <Typography
              fontSize={{ md: "3vw", xs: "6vw" }}
              zIndex={10}
              fontWeight={700}
              color="white"
            >
              Be The First To Know
            </Typography>
            <Typography
              fontSize={{ md: "16px", xs: "12px" }}
              fontWeight={400}
              color="white"
            >
              Subscribe to our community to stay informed about our latest
              updates, special offers, and exclusive content specifically
              tailored for cohorts. Be the first to receive insights, resources,
              and opportunities that can help you succeed. Sign up now and
              become part of a network dedicated to your growth and success!
            </Typography>
          </Grid>
          <Grid item lg={1}></Grid>
          <Grid item xs={12} lg={5}>
            <Box className="subscriptionSide" style={{ margin: "3rem 0  0 0" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => {
                  console.log(values);

                  handleSubscribe(values);
                  resetForm();
                  console.log(values, "fghgjhg");
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box className="subscriptionTextField">
                      <Box
                        className="subscriptionBtn"
                        // style={{
                        //   display: "flex",
                        //   justifyContent: "end",
                        // }}
                      >
                        <TextField
                          className="textFieldCls"
                          // height="300px"
                          inputProps={{
                            maxLength: 300,
                            sx: {
                              height: "20px",
                              // width: { lg: "20vw", xs: "35vw" },
                              backgroundColor: "white",
                              borderRadius: "5px",
                            },
                          }}
                          onInput={(e) => {
                            // e.target.value =
                            //   e.target.value.charAt(0).toLowerCase() +
                            //   e.target.value.slice(1);
                            setSubscribe(true);
                            let value = e.target.value;
                            if (value.length > 80) {
                              value = value.slice(0, 80);
                            }
                            // value = value.charAt(0).toLowerCase() + value.slice(1);
                            e.target.value = value;
                          }}
                          variant="outlined"
                          name="email"
                          placeholder="Enter your email address"
                          onChange={(e) => {
                            handleChange(e);
                            setErrorMessage("");
                          }}
                          onKeyDown={() => setErrorMessage("")}
                          onBlur={(e) => {
                            handleBlur(e);
                            setErrorMessage("");
                          }}
                          value={values.email}
                          helperText={touched.email && errors.email}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                              position: "absolute",
                              top: "50px",
                            },
                          }}
                          error={touched.email && Boolean(errors.email)}
                        />

                        <Button
                          my={2}
                          sx={{
                            background:
                              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                            padding: { md: "12px 25px", xs: "10px 15px" },
                            borderRadius: "5px",
                            margin: "0 0 0 10px",
                            fontSize: { md: "16px", xs: "13px" },
                            textTransform: "none",
                          }}
                          variant="contained"
                          // disabled={!subscribe}
                          type="submit"
                          disabled={
                            loading || !subscribe || values.email.length < 1
                          }
                          // onClick={handleSubscribe}
                        >
                          Subscribe
                          {loading && (
                            <CircularProgress size={20} sx={{ ml: 2 }} />
                          )}
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          width: { md: "550px", xs: "auto" },
                          textAlign: "justify",
                        }}
                      >
                        {errorMessage && (
                          <FormHelperText
                            sx={{
                              color: "#df5d05",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
            {/* <Box
            component={"span"}
            sx={{
              paddingLeft: "100px",
              color: "#d32f2f",
              fontSize: "18px",
              position: "absolute",
            }}
            fontWeight={"500"}
          >
            {errorMessage}
          </Box> */}
          </Grid>
        </Grid>
      </div>
    </MainStyledComponent>
  );
};

export default Guestuserhome;
