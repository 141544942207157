import React, { useEffect, useState } from "react";
import "./guestCohort.scss";
import {
  Button,
  CircularProgress,
  Grid,
  Box,
  Pagination,
  Divider,
  Container,
} from "@mui/material";
import PasswordResetModal from "../../components/PasswordResetModal";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import customAxios from "../../utils/customAxios";
import { current } from "@reduxjs/toolkit";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/system";
export default function GuestCohort() {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isOfferingsOpen, setIsOfferingsOpen] = useState(false);
  const [offeringState, setOfferingState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const navigate = useNavigate();
  const [cohortList, setCohortList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  // const largeScreenUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const largeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  // const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const extraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
  };
  const handleOfferingsModalOpen = () => {
    setIsOfferingsOpen(true);
  };
  const handleDescriptionModalOpen = () => {
    setIsDescriptionOpen(true);
  };

  const handleCloseModals = () => {
    setLogoutModalOpen(false);
    setIsOfferingsOpen(false);
    setIsDescriptionOpen(false);
  };

  // useEffect(() => {
  //   initialDataLoader();
  // }, []);

  const userApplyForCohorts = async (id) => {
    try {
      const token = sessionStorage.getItem("userToken");
      if (!token) {
        navigate("/login");
        return;
      }
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userApplyForCohort,
        headers: {
          token: token,
        },
        data: {
          cohort_id: id,
        },
      });
      console.log(res);

      if (!res?.data) {
        return;
      }
      setLogoutModalOpen(true);
    } catch (err) {
      toast.error(err?.response?.data?.responseMessage);
      console.log(err, "akjgasf");
    }
  };

  const initialDataLoader = async () => {
    const status = sessionStorage.getItem("userToken") ? "USER" : "GUEST";
    try {
      setIsLoading(true);
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllCohort,
        params: {
          status: status,
          page: page,
          limit: 10,
        },
      });
      setIsLoading(false);
      console.log(res, "akjsguafaf");
      if (!res?.data) {
        return;
      }
      setCohortList(res?.data?.result?.cohorts || []);
      setNoOfPages(res?.data?.result?.totalPages);
    } catch (err) {
      setIsLoading(false);
      console.log(err, "akjgasf");
    }
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // console.log("noOfPages", noOfPages);
  const buttonStyle = {
    color: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.12)",
  };
  const PrevbuttonStyle = {
    color: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
  };
  const handleNext = () => {
    console.log("before", noOfPages, page);
    if (page < noOfPages) {
      setPage(page + 1); // Increment the page only if it's not the last page
      console.log("after", noOfPages, page);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    initialDataLoader();
  }, [page]);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        width={"100vw"}
        height={"80vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress
          sx={{ height: "180px", width: "180px", color: "#ce4920" }}
        />
      </Grid>
    );
  }

  return (
    <>
      <Box style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}>
        <Container
          maxWidth="lg"
          color={currentTheme == "DARK" ? "#fff" : "#000"}
        >
          <div className="main-wrapper">
            <h1
              className="cohort-heading"
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            >
              Cohort List
            </h1>

            {cohortList
              ?.filter((item, idx) => item?.cohort.is_active === true)
              ?.map((item, idx) => (
                <div
                  style={{
                    // backgroundColor: idx % 2 === 0 ? "#f2f2f2" : "transparent",
                    backgroundColor:
                      currentTheme === "DARK"
                        ? idx % 2 === 0
                          ? "#000"
                          : "#000"
                        : idx % 2 === 0
                          ? "#f2f2f2"
                          : "transparent",
                    borderRadius: "8px   ",
                  }}
                  className="main-container"
                >
                  <div
                    className="first-section"
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    <h2
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {item?.cohort?.cohortName
                        ? item?.cohort?.cohortName
                        : "NA"}
                    </h2>
                    <div className="span-container">
                      <span
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#523168",
                        }}
                      >
                        {item.associatedMembers > 1
                          ? "Associated Members  - "
                          : "Associated Member  - "}

                        {item?.associatedMembers ? item?.associatedMembers : 0}
                      </span>
                      {/* <span className="price-span"> Joining Price - $500</span> */}
                    </div>
                  </div>
                  <Divider
                    style={{
                      width: "100%",
                      border: "1px solid #aeaeae",
                      margin: "20px 0px  20px 0px",
                    }}
                  />
                  <Grid style={{ marginTop: "30px" }} container fullWidth>
                    <Grid md={4} item>
                      <img
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                          height: "320px",
                          objectFit: "cover",
                        }}
                        src={item?.cohort?.image_url}
                      />
                    </Grid>
                    <Grid md={0.5} item></Grid>
                    <Grid mt={1} md={7} item>
                    <h3
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#523168",
                          marginBottom: "20px",
                        }}
                        //marginBottom={{xs: "20px"}}
                      >
                        DESCRIPTION
                      </h3>
                      <p
                        className={`wordBreak responsive-margin`}
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {item?.cohort?.description
                          ? item?.cohort?.description.length > 275
                            ? `${item?.cohort?.description.slice(0, 275)}` +
                            "..."
                            : item?.cohort?.description
                          : "NA"}
                          {item?.cohort?.description &&
                            item?.cohort?.description.length > 275 && (
                              <Button
                                onClick={() => {
                                  handleDescriptionModalOpen();
                                  setDescriptionState(
                                    item?.cohort?.description
                                      ? item?.cohort?.description
                                      : "NA"
                                  );
                                }}
                                variant="text"
                                style={{
                                  padding: "0px",
                                  textTransform: "none",
                                  color: "#523169",
                                  fontWeight: 600,
                                  fontSize: "1rem"
                                }}
                              >
                                Read More
                              </Button>
                            )}
                        {/*{item?.cohort?.description?.length > 275 && (
                          <span
                            onClick={() => {
                              handleDescriptionModalOpen();
                              setDescriptionState(
                                item?.cohort?.description
                                  ? item?.cohort?.description
                                  : "NA"
                              );
                            }}
                            style={{
                              color: "#523169",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            {" Read more"}
                          </span>
                        )}*/}
                      </p>
                      <h3
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#523168",
                        }}
                      >
                        OFFERINGS
                      </h3>
                      <p
                        className="wordBreak"
                        style={{
                          marginTop: "20px",
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {item?.cohort?.offerings
                          ? item?.cohort?.offerings.length > 275
                            ? `${item?.cohort?.offerings.slice(0, 275)}` + "..."
                            : item?.cohort?.offerings
                          : "NA"}
                          {item?.cohort?.offerings &&
                            item?.cohort?.offerings.length > 200 && (
                              <Button
                                onClick={() => {
                                  handleOfferingsModalOpen();
                                  setOfferingState(
                                    item?.cohort?.offerings
                                      ? item?.cohort?.offerings
                                      : "NA"
                                  );
                                }}
                                variant="text"
                                style={{
                                  padding: "0px",
                                  textTransform: "none",
                                  color: "#523169",
                                  fontWeight: 600,
                                  fontSize: "1rem"
                                }}
                              >
                                Read more
                              </Button>
                            )}
                        {/*{item?.cohort?.offerings.length > 275 && (
                          <span
                            onClick={() => {
                              handleOfferingsModalOpen();
                              setOfferingState(
                                item?.cohort?.offerings
                                  ? item?.cohort?.offerings
                                  : "NA"
                              );
                            }}
                            style={{
                              color: "#523169",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            Read more
                          </span>
                        )}*/}
                      </p>
                      <div className="third-section">
                        <div className="inner-container">
                          <h4>
                            <span
                              style={{
                                color:
                                  currentTheme == "DARK" ? "#fff" : "#08051599",
                              }}
                            >
                              Duration:
                            </span>

                            <h5
                              style={{
                                color: currentTheme == "DARK" ? "#fff" : "#000",
                              }}
                            >
                              {" "}
                              {item?.cohort?.duration
                                ? item?.cohort?.duration
                                : "NA"}{" "}
                              days
                            </h5>
                          </h4>
                          <h4>
                            <span
                              style={{
                                color:
                                  currentTheme == "DARK" ? "#fff" : "#08051599",
                              }}
                            >
                              Start Date:
                            </span>

                            <h5
                              style={{
                                color: currentTheme == "DARK" ? "#fff" : "#000",
                              }}
                            >
                              {" "}
                              {item?.cohort?.startDate
                                ? dayjs(item?.cohort?.startDate)?.format("ll")
                                : "NA"}
                            </h5>
                          </h4>
                          <h4>
                            <span
                              style={{
                                color:
                                  currentTheme == "DARK" ? "#fff" : "#08051599",
                              }}
                            >
                              End Date:
                            </span>

                            <h5
                              style={{
                                color: currentTheme == "DARK" ? "#fff" : "#000",
                              }}
                            >
                              {" "}
                              {item?.cohort?.endDate
                                ? dayjs(item?.cohort?.endDate)?.format("ll")
                                : "NA"}
                            </h5>
                          </h4>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                          className="inner-button"
                        >
                          <Button
                            sx={{
                              marginX: "auto",
                              textTransform: "none",
                            }}
                            className="apply-button"
                            onClick={(e) => {
                              e.preventDefault();
                              userApplyForCohorts(item?.cohort?.id);
                              // userApplyForCohort(item?.cohort?.id)
                              // handleOpenLogoutModal()
                              // navigate(`/guest-community/${item?.cohort?.id}`);
                              // navigate('/login')
                            }}
                            variant="contained"
                          >
                            Apply
                          </Button>
                          {/* <Button
                            sx={{
                              background:
                                "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                              marginX: "auto",
                              marginBottom: "20px",
                              width: "200px",
                              fontSize: "16px",
                              textTransform: "none",
                              borderRadius: "10px",
                            }}
                            onClick={(e) => {
                              e.preventDefault();

                              // userApplyForCohort(item?.cohort?.id)
                              // handleOpenLogoutModal()
                              // navigate(`/guest-community/${item?.cohort?.id}`);
                              navigate(`/guest-community/${item?.cohort?.id}`);
                            }}
                            variant="contained"
                          >
                            Community Chat
                          </Button> */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))}
            <div className="pagination-wrapper">
              {" "}
              <Box
                mt={3}
                mb={1}
                style={{
                  display: noOfPages > 1 ? "flex" : "none",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  style={PrevbuttonStyle}
                  variant="outlined"
                  disabled={page === 1}
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
                <Pagination
                  count={noOfPages}
                  page={page}
                  // className={
                  //   // currentTheme == "DARK" ? "pagination-dark" : "pagination"
                  // }
                  onChange={handleChange}
                  hideNextButton={true}
                  hidePrevButton={true}
                // sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                />
                <Button
                  variant="outlined"
                  disabled={page === noOfPages}
                  onClick={handleNext}
                  style={buttonStyle}
                >
                  Next
                </Button>
              </Box>{" "}
            </div>
            <PasswordResetModal
              type="cohort"
              open={isLogoutModalOpen}
              onClose={handleCloseModals}
            />
            <PasswordResetModal
              type="offerings"
              open={isOfferingsOpen}
              onClose={handleCloseModals}
              offeringState={offeringState}
            />
            <PasswordResetModal
              type="descriptionCohort"
              open={isDescriptionOpen}
              onClose={handleCloseModals}
              descriptionState={descriptionState}
            />
          </div>
        </Container>
      </Box>
    </>
  );
}
