import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

const learnObj = [
  {
    heroImg: "/images/hacker.png",
    heading: " Course Category 1",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
  {
    heroImg: "/images/ai.png",

    heading: " Course Category 1",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
  {
    heroImg: "/images/locking.png",

    heading: " Course Category 1",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
];

const listArray = [
  "From a competitive standpoint, BOOST!™ brings a set of resources and tools that are usually cost-prohibitive for many business owners. We're activating the entrepreneurial ecosystem, connecting business owners with essential resources for sustainable growth.",
  "Additionally, our tailored mentorship programs pair entrepreneurs with industry experts who provide personalized guidance and actionable strategies, ensuring each business receives the specific support it needs.",
  " Unlike many accelerator programs, we emphasize a community-driven approach, fostering a collaborative environment where members can share insights, challenges, and successesstrengthening the entire network. ",
  "Our partnership with local leaders and educational institutions ensures that our members benefit from a holistic support system, including access to cutting-edge research, training, and community initiatives that drive economic development and innovation.",
  "Furthermore, we offer exclusive access to funding opportunities and investor networks, helping businesses secure the capital they need to scale and succeed in a competitive marketplace. ",
];

const Accelarator = () => {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      {/* <Box sx={{ position: "relative", width: "100dvw" }}>
        <Typography
          sx={{
            position: "absolute",
            left: { lg: "100px", md: "70px", xs: "50px" },
            top: { lg: "280px", md: "200px", xs: "50px" },
            fontSize: { md: "60px", sm: "30px", xs: "20px" },
            zIndex: "1",
            width: "35vw",
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent", // Hide the actual text
          }}
        >
          IS THIS JUST ANOTHER BUSINESS ACCELERATOR?
        </Typography>
     
        <Box
          sx={{ width: "100vw", position: "absolute", zIndex: "1" }}
          component="img"
          src="/images/meetvector.png"
        />
        <Box
          sx={{ width: "100vw", position: "absolute" }}
          component="img"
          src="/images/purpleblur.png"
        />
        <Box sx={{ width: "90vw" }} component="img" src="/images/meet.png" />
      </Box> */}
      <div
        className="heroBannerContainer"
        style={{
          backgroundImage: 'url("./images/accelarator.png")',
        }}
      >
        <Box
          sx={{
            height: "550px",
            position: "absolute",
            left: "900px",
            top: "100px",
            zIndex: "1",
            borderRadius: "50%",
            display: { xl: "block", xs: "none" },
          }}
          component="img"
          src="/images/specsman.png"
        />
        <Typography
          mx={20}
          my={20}
          sx={{
            // position: "absolute",
            // left: { lg: "100px", md: "70px", xs: "50px" },
            // top: { lg: "200px", md: "200px", xs: "50px" },
            fontSize: { lg: "60px", md: "45px", sm: "30px", xs: "20px" },
            zIndex: "1",
            width: { lg: "38vw", xs: "67vw" },
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent", // Hide the actual text
            marginX: { lg: "60px" /* sm: "100px", xs: "75px" */ },
            marginY: { lg: "60px" /* sm: "120px", xs: "90px" */ },
          }}
        >
          IS THIS JUST ANOTHER BUSINESS ACCELERATOR?
        </Typography>
      </div>
      <Box sx={{ paddingX: { md: "60px" } }} py={6}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
          }}
        >
          The first answer to that question is, "Even if we were, there’s
          nothing wrong with that!" There is such a need to provide support to
          historically under-resourced businesses and all emerging businesses
          that our philosophy is, "The more the merrier!" Our programs are not
          in competition, but are actually collaborative partners that are part
          of a larger story, but more about that later!
        </Typography>
        <Typography
          mt={4}
          mb={2}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          Question: How is Business BOOST! Society™ different from other
          accelerator programs?
          <br />
          <br />
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#000",
            }}
          >
            {" "}
            Business BOOST! Society™ stands out from other accelerator programs
            in several key ways:
          </Typography>
        </Typography>
        {listArray.map((val, idx) => (
          <li
            style={{
              fontSize: "16px",
              fontWeight: "300",
              color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
              textAlign: "justify",
              margin: "16px 0px",
            }}
          >
            {val}
          </li>
        ))}
        <Typography
          mt={4}
          mb={2}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          At Business BOOST! Society™, we are dedicated to providing a unique
          and impactful accelerator program that focuses on your specific needs,
          fosters a thriving community, and empowers you to achieve lasting
          success in your business endeavors.
        </Typography>
      </Box>
    </Box>
  );
};

export default Accelarator;
