import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import { toast } from "react-toastify";
import {
  ChevronRight,
  KeyboardArrowDown,
  RemoveRedEye,
  VisibilityOff,
} from "@mui/icons-material";
import "./passwordsecurity.scss";
import Sidebar from "../../../components/profileSidebar/Sidebar";
import PasswordResetModal from "../../../components/PasswordResetModal";
import { ApiConfig } from "../../../services/ApiConfig";
import "./passwordsecurity.scss";
import { useNavigate } from "react-router";
import customAxios from "../../../utils/customAxios";
import axios from "axios";
import { useSelector } from "react-redux";

const MainComponent = styled("Box")(({ theme }) => ({
  "& .parentClass": {
    "& h6": {
      textAlign: "start !important",
    },
    "& .passWordTextfiled": {
      width: "75%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
}));

const PasswordSecurity = () => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenDisable, setModalOpenDisable] = useState(false);
  const [isModalOpenDisableSMS, setModalOpenDisableSMS] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordHiden, setIsPasswordHidden] = useState(false);
  const [isConfirmHidden, setIsConfirmHidden] = useState(false);
  const [type, setType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [is2faOpen, setIs2faOpen] = useState(false);
  const [is2faEmailVerified, setIs2faEmailVerified] = useState(false);

  const [is2faSmsVerified, setIs2faSmsVerified] = useState(false);

  const handleEmail = () => {
    // Handle successful password reset logic
    // setIsChecked(true);
    setModalOpen(true);
    setType("authenticateEmail");
  };
  const handleSms = () => {
    // Handle successful password reset logic
    setModalOpen(true);
    setType("authenticateSms");
  };
  const handleSmsDisable = () => {
    setModalOpen(false);
    setModalOpenDisableSMS(true);
    setType("authenticateSms");
  };

  const handle2faOpen = () => {
    setIs2faOpen(true);
    setType("authenticate2faEmail");
  };
  const handle2faSMSOpen = () => {
    setIs2faOpen(true);
    setType("authenticate2faSMS");
  };
  const handleEmailDisable = () => {
    setModalOpen(false);
    setModalOpenDisable(true);
    setType("authenticateEmail");
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setModalOpenDisable(false);
    setModalOpenDisableSMS(false);
    setIs2faOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getUserDetails = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getUserDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        const isEmail2FAEnabled = res.data.result.is_email_2fa;
        const isSms2FAEnabled = res.data.result.is_sms_2fa;
        sessionStorage.setItem("is_email_2fa", String(isEmail2FAEnabled));
        setIs2faEmailVerified(isEmail2FAEnabled);
        setIs2faSmsVerified(isSms2FAEnabled);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function hitApi() {
    let option = {
      method: "POST",
      url: ApiConfig.zoomuserInfo,
    };
    const res = await customAxios(option);

    if (res) {
      console.log("url", res.data);
      window.open(res.data);
    }
  }

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .trim()
      .required("Old password is required.")
      .min(6, "Password must be at least 6 characters."),

    password: Yup.string()
      .trim()
      .required("New password is required.")
      .min(6, "Please enter at least 6 characters.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,

        "Password requires uppercase, lowercase, number, and special character."
      )
      .test(
        "passwords-match",
        "New password must not be the same as the current password.",
        function (value) {
          return value !== this.parent.oldPassword;
        }
      ),
    confirmPassword: Yup.string()
      .required("Confirmation of your password is required.")
      .oneOf([Yup.ref("password"), null], "Passwords must match."),
  });

  const FormContainer = styled(Form)({
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",

    // "@media(max-width:640px)": {
    //   width: "100%",
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
  });

  // const AntSwitch = styled(Switch)(({ theme }) => ({
  //   width: 44,
  //   height: 21,
  //   padding: 0,
  //   display: "flex",
  //   "&:active": {
  //     "& .MuiSwitch-thumb": {
  //       width: 17,
  //     },
  //     "& .MuiSwitch-switchBase.Mui-checked": {
  //       transform: "translateX(23px)",
  //     },
  //   },
  //   "& .MuiSwitch-switchBase": {
  //     padding: 2,
  //     "&.Mui-checked": {
  //       transform: "translateX(23px)",
  //       color: "#fff",
  //       "& + .MuiSwitch-track": {
  //         opacity: 1,
  //         background:
  //           theme.palette.mode === "dark"
  //             ? "#177ddc"
  //             : " linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)", // Replace with the desired orange color
  //       },
  //     },
  //   },
  //   "& .MuiSwitch-thumb": {
  //     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
  //     width: 17,
  //     height: 17,
  //     borderRadius: 8,
  //     transition: theme.transitions.create(["width"], {
  //       duration: 200,
  //     }),
  //   },
  //   "& .MuiSwitch-track": {
  //     borderRadius: 20 / 2,
  //     opacity: 1,
  //     backgroundColor:
  //       theme.palette.mode === "dark"
  //         ? "rgba(255,255,255,.35)"
  //         : "rgba(0,0,0,.25)",
  //     boxSizing: "border-box",
  //   },
  //   "    & css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled ":
  //     {
  //       color: "#fff",
  //     },
  // }));

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 21,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 17,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(23px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(23px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background:
            theme.palette.mode === "dark"
              ? "#177ddc"
              : "linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)", // Replace with the desired orange color
        },
      },
      "&.Mui-disabled": {
        // Correct selector for disabled state
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1, // You can adjust the opacity for the disabled state
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 17,
      height: 17,
      borderRadius: 8,
      backgroundColor: "#fff",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const authenticateSms = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.update2faSmsStatus,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          status: true,
        },
      });

      if (res.data?.statusCode === 200) {
        setIs2faSmsVerified(true);
        getUserDetails();
        // toast.success("OTP sent successsfully.");
        // setLoading(false);
        // setErrorMessage("");
        // actions.resetForm({
        //   values: {
        //     oldPassword: "",
        //     password: "",
        //     confirmPassword: "",
        //   },
        // });
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };
  const disableauthenticateSms = async () => {
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.update2faSmsStatus,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          status: false,
        },
      });

      if (res.data?.statusCode === 200) {
        setIs2faSmsVerified(false);
        // toast.success("OTP sent successsfully.");
        // setLoading(false);
        // setErrorMessage("");
        // actions.resetForm({
        //   values: {
        //     oldPassword: "",
        //     password: "",
        //     confirmPassword: "",
        //   },
        // });
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };

  const authenticateEmail = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.update2faEmailStatus,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          status: true,
        },
      });

      if (res.data?.statusCode === 200) {
        setIs2faEmailVerified(true);
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };
  const disableAuthenticateEmail = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.update2faEmailStatus,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          status: false,
        },
      });

      if (res.data?.statusCode === 200) {
        setIs2faEmailVerified(false);
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };

  const handleChangePassword = async (values, actions) => {
    setLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.changePassword,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          currentPassword: values.oldPassword,
          newPassword: values.password,
        },
      });

      if (res.data?.statusCode === 200) {
        toast.success("Password changed successfully.");
        setLoading(false);
        setErrorMessage("");
        navigate("/my-profile");
        actions.resetForm({
          values: {
            oldPassword: "",
            password: "",
            confirmPassword: "",
          },
        });
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong");
        setLoading(false);
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
      setLoading(false);
    }
  };
  const handleChange2faSms = () => {
    if (!is2faSmsVerified) {
      handleSms();
      authenticateSms();
    } else {
      handleSmsDisable();
      disableauthenticateSms();
    }
  };
  const handleChange2faEmail = () => {
    if (!is2faEmailVerified) {
      handleEmail();
    } else {
      handleEmailDisable();
    }
  };
  useEffect(() => {
    getUserDetails();
  });
  // useEffect(() => {
  //   const isEmail2fa = sessionStorage.getItem("is_email_2fa") === "true";
  //   setIs2faEmailVerified(isEmail2fa);
  // }, [sessionStorage.getItem("is_email_2fa")]);
  return (
    <MainComponent>
      <div
        style={{
          backgroundColor: currentTheme == "DARK" ? "#151515" : "#f8f8f8",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: { sm: "70px", xs: "35px" },
            backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
          }}
        >
          <img
            style={{ cursor: "pointer", width: "30px" }}
            onClick={() => navigate("/my-profile")}
            src="/images/backicon.png"
            alt=""
          />
          <h1
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            className="profile-heading"
          >
            Password & Security
          </h1>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            padding: { sm: "0.6% 70px 1rem 70px", xs: "1% 20px 1rem 20px" },
            WebkitAlignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: { md: "block", xs: "none" },

              margin: 0,
              padding: 0,
              // height: "700px",
              // paddingBottom:"30px",
              width: "450px",

              overflow: "none",
              alignItems: "center",
              gap: "2rem",
              flexDirection: "column",
              "&::-webkit-scrollbar": {
                display: "none", // For Safari and Chrome
              },
              "@media(max-width:768px)": {
                ml: "0px",
              },
            }}
          >
            <Sidebar />
          </Box>
          <div
            style={{
              width: "100%",
              overflow: "auto",
              // boxShadow: "rgba(0, 0, 0, 0.07) 6px 1px 18px 5px",
              // boxShadow: "rgba(0, 0, 0, 0.07) 6px 1px 18px 5px",
              backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
              padding: "0px 30px",
            }}
            className="main-wrapper"
          >
            <div className="head">
              <h1
                style={{
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                2-Factor Authentication
              </h1>

              <p
                style={{
                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                To protect your account, it is recommended to turn on at least
                one 2FA
              </p>
            </div>
            <hr style={{ border: "1.5px solid #161E2933" }} />
            <div
              className="headings"
              style={{
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
              }}
            >
              <h2
                style={{
                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                SMS Authenticator
              </h2>
              <FormGroup>
                <AntSwitch
                  checked={is2faSmsVerified}
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={() => {
                    // sessionStorage.removeItem("userToken");
                    // navigate("/");
                    !is2faSmsVerified
                      ? handle2faSMSOpen()
                      : disableauthenticateSms();
                    // sessionStorage.removeItem("minutes");
                    // sessionStorage.removeItem("seconds");

                    // handleChange2faSms();
                  }}
                  // style={{
                  //   backgroundColor: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                  // }}
                />
              </FormGroup>
            </div>
            <hr style={{ border: "1.5px solid #161E2933" }} />
            <div
              className="headings"
              style={{
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
              }}
            >
              <h2
                style={{
                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                Email Authenticator
              </h2>
              <FormGroup>
                <AntSwitch
                  checked={is2faEmailVerified}
                  onClick={() => {
                    !is2faEmailVerified
                      ? handle2faOpen()
                      : disableAuthenticateEmail();
                    // !is2faEmailVerified
                    //   ? authenticateEmail()
                    //   : disableAuthenticateEmail();
                  }}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </FormGroup>
            </div>
            <hr style={{ border: "1.5px solid #161E2933" }} />
            <div>
              <Accordion
                expanded={expanded}
                onChange={handleChange(!expanded)}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",

                  boxShadow: "none",
                }}
                classes={{
                  root: "custom-accordion-root",
                  expanded: "custom-accordion-expanded",
                }}
              >
                <AccordionSummary
                  className="headings"
                  style={{
                    padding: "0",
                    borderBottom: "2.5px solid #161E2933",
                  }}
                  expandIcon={
                    expanded ? (
                      <span className="accordianIcon">
                        <KeyboardArrowDown fontSize="medium" />
                      </span>
                    ) : (
                      <span className="accordianIcon">
                        <ChevronRight fontSize="medium" />
                      </span>
                    )
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h2
                    style={{
                      margin: "14px 0",
                      color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                    }}
                  >
                    Change Password
                  </h2>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: "0" /* display: "flex" */ }}
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      handleChangePassword(values, actions);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      values,
                    }) => (
                      <Box className="parentClass">
                        <FormContainer
                          sx={{
                            width: { sm: "50%", xs: "100%" },
                            margin: "-1rem 0 2rem 0",
                          }}
                        >
                          {values && (
                            <Typography
                              style={{
                                textAlign: "left",
                                color: "#d32f2f",
                                fontSize: "18px",
                                backgroundColor: "#fff",
                              }}
                              fontWeight={"500"}
                              mt={2}
                            >
                              {errorMessage}
                            </Typography>
                          )}
                          <Box>
                            <Typography
                              sx={{
                                color:
                                  currentTheme == "DARK" ? "#a3a3a3" : "#000",
                                textAlign: "left",
                              }}
                              variant="h6"
                            >
                              Old Password
                            </Typography>
                            <TextField
                              className="passWordTextfiled"
                              inputProps={{
                                maxLength: 20,
                                style: {
                                  height: "20px",
                                },
                              }}
                              style={{
                                marginBottom: "16px",
                                borderRadius: "5px",
                              }}
                              value={values.oldPassword}
                              variant="outlined"
                              name="oldPassword"
                              placeholder="Enter old password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type={isHidden ? "text" : "password"}
                              error={
                                touched.oldPassword &&
                                Boolean(errors.oldPassword)
                              }
                              helperText={
                                touched.oldPassword && errors.oldPassword
                              }
                              FormHelperTextProps={{
                                style: {
                                  marginLeft: "0px",
                                  fontSize: "12px",
                                  marginTop: "2px",
                                },
                              }}
                              onInput={(e) => {
                                const regex = /^[^\s]*$/;

                                if (!regex.test(e.target.value)) {
                                  e.target.value =
                                    e.target.dataset.previousValue || "";
                                } else {
                                  e.target.dataset.previousValue =
                                    e.target.value;
                                }
                                setErrorMessage("");
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setIsHidden((p) => !p)}
                                    >
                                      {isHidden ? (
                                        <RemoveRedEye />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                color:
                                  currentTheme == "DARK" ? "#a3a3a3" : "#000",
                                textAlign: "left",
                              }}
                              variant="h6"
                            >
                              New Password
                            </Typography>
                            <TextField
                              className="passWordTextfiled"
                              inputProps={{
                                maxLength: 20,
                                style: {
                                  height: "20px",
                                  backgroundColor: "#fff",
                                },
                              }}
                              style={{
                                marginBottom: "16px",
                                // backgroundColor: "#fff",
                                borderRadius: "5px",
                              }}
                              variant="outlined"
                              value={values.password}
                              name="password"
                              placeholder="Enter new password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type={isNewPasswordHidden ? "text" : "password"}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              helperText={touched.password && errors.password}
                              onInput={(e) => {
                                const regex = /^[^\s]*$/;

                                if (!regex.test(e.target.value)) {
                                  e.target.value =
                                    e.target.dataset.previousValue || "";
                                } else {
                                  e.target.dataset.previousValue =
                                    e.target.value;
                                }
                                setErrorMessage("");
                              }}
                              FormHelperTextProps={{
                                style: {
                                  marginLeft: "0px",
                                  fontSize: "12px",
                                  marginTop: "2px",
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setIsNewPasswordHidden((p) => !p)
                                      }
                                    >
                                      {isNewPasswordHidden ? (
                                        <RemoveRedEye />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                          <Box>
                            <Typography
                              sx={{
                                color:
                                  currentTheme == "DARK" ? "#a3a3a3" : "#000",
                                textAlign: "left",
                              }}
                              variant="h6"
                            >
                              Confirm Password
                            </Typography>
                            <TextField
                              className="passWordTextfiled"
                              inputProps={{
                                maxLength: 20,
                                onPaste: (e) => e.preventDefault(),

                                style: {
                                  height: "20px",
                                  // backgroundColor: "red",
                                },
                              }}
                              style={{
                                marginBottom: "16px",
                                // backgroundColor: "#fff",
                                borderRadius: "5px",
                              }}
                              variant="outlined"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              placeholder="Enter confirm password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type={isConfirmHidden ? "text" : "password"}
                              onInput={(e) => {
                                const regex = /^[^\s]*$/;

                                if (!regex.test(e.target.value)) {
                                  e.target.value =
                                    e.target.dataset.previousValue || "";
                                } else {
                                  e.target.dataset.previousValue =
                                    e.target.value;
                                }
                                setErrorMessage("");
                              }}
                              error={
                                touched.confirmPassword &&
                                Boolean(errors.confirmPassword)
                              }
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              FormHelperTextProps={{
                                style: {
                                  marginLeft: "0px",
                                  fontSize: "12px",
                                  marginTop: "2px",
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setIsConfirmHidden((p) => !p)
                                      }
                                    >
                                      {isConfirmHidden ? (
                                        <RemoveRedEye />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                          <Box
                            my={2}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              // alignItems: "center",
                            }}
                          >
                            <Button
                              sx={{
                                background:
                                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                                borderRadius: "10px",
                                fontSize: "18px",
                                paddingX: "30px",
                                paddingY: "5px",
                                textTransform: "none",
                              }}
                              variant="contained"
                              type="submit"
                              disabled={loading}
                            >
                              Submit
                              {loading && (
                                <CircularProgress
                                  size={20}
                                  sx={{
                                    marginLeft: "10px",
                                    color: "white",
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </FormContainer>
                      </Box>
                    )}
                  </Formik>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <Button variant="contained" onClick={hitApi}>
          Hit
          </Button> */}
          </div>
        </Box>
        <PasswordResetModal
          type={type}
          open={isModalOpen}
          onClose={handleCloseModal}
          setIsVerified={setIs2faEmailVerified}
          setIs2faSmsVerified={setIs2faSmsVerified}
        />
        <PasswordResetModal
          type={type}
          open={is2faOpen}
          onClose={handleCloseModal}
          authenticateEmail={authenticateEmail}
          authenticateSMS={authenticateSms}
        />
        <PasswordResetModal
          type="authenticateDisableSms"
          open={isModalOpenDisableSMS}
          onClose={handleCloseModal}
          setIs2faSmsVerified={setIs2faSmsVerified}
        />

        {/* <PasswordResetModal
        type="authenticateDisableEmail"
        open={isModalOpenDisable}
        onClose={handleCloseModal}
        setIsVerified={setIs2faEmailVerified}
      /> */}
      </div>
    </MainComponent>
  );
};

export default PasswordSecurity;
