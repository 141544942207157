import React, { useEffect, useRef, useState } from "react";
import {
  AccountCircleOutlined,
  ArrowBack,
  AttachFile,
  KeyboardVoice,
  PhotoCamera,
  Search,
  Send,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../community/MasterMindCommunity";
import customAxios from "../../utils/customAxios";
import { ApiConfig, socketUrl } from "../../services/ApiConfig";
import { useLocation, useNavigate, useParams } from "react-router";
import { Container } from "@mui/system";
import io from "socket.io-client";
// import { socketEvent } from "../guestUser/guestSocketConfig/guestSocket";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import Webcam from "react-webcam";
import ScheduleCallPopUp from "../../components/scheduleAction/ScheduleCallPopUp";
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function AdvisorChat() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentChat, setCurrentChat] = useState(
    sessionStorage.getItem("currentChat") || 0
  );
  const [topicFilter, setTopicFilter] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [currentTopic, setCurrentTopic] = useState(
    {} || sessionStorage.getItem(currentTopic)
  );
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isConnected, setIsConnected] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [scheduleModal, setScheduleModal] = useState(false);
  // const [myUniqueId, setmyUniqueId] = useState(localStorage.getItem("userId"));
  const [roomId, setRoomId] = useState("");
  const [message, setMessage] = useState("");
  const [BOList, setBOList] = useState("");
  const webcamRef = React.useRef(null);
  const [updateScheduleCallList, setUpdateScheduleCallList] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [currentBO, setCurrentBO] = useState("");
  const chatScreen = useRef();
  const [isListening, setIsListening] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [displayMessages, setDisplayMessages] = useState([]); // State for messages to display
  const [messageOffset, setMessageOffset] = useState(30);
  const socketRef = useRef(null);
  const isMediumScreen = useMediaQuery("(max-width:900px)"); // Example breakpoint for medium screens

  const socketEvent = {
    initiateChat: "initiateUserChat",
    joinChatRoom: "joinChatRoom",
    sendMessage: "userSendMessage",
    userMessage: "getUserMessage",
  };

  console.log(location?.state);
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        secure: true,
        reconnect: true,
      });
    }

    return () => {
      // Clean up socket when component unmounts
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);
  const userId = sessionStorage.getItem("userId");
  const advisorId = location?.state?.advId;
  const advName = location?.state?.advName;

  useEffect(() => {
    if (isConnected) {
      handleInitialSocketConnect();
    }
  }, [isConnected]);

  useEffect(() => {
    // debugger;
    if (isConnected) {
      receiveMessageHandler();
      userMessageHandler();
    }
  }, [isConnected]);

  useEffect(() => {
    console.log(socketRef);
    if (socketRef.current) {
      socketRef.current.on("connect", () => setIsConnected(true));
      return () => {
        socketRef?.current?.off("connect");
        socketRef?.current?.off(socketEvent.initiateChat);
      };
    }
  }, [roomId]);

  const handleChatData = () => {
    if (socketRef.current) {
      // debugger;
      console.log("hellooioioieopiepoiepoe");
      socketRef.current.on("userChatInitiated", (data) => {
        console.log(data, "data");
        setMessageList([]);
        setMessageList(data?.messages);
        // setMessageList(data?.messages.slice(0, 30));
        setRoomId(data?.chatRoomId);
        setInitialLoading(false);
      });
    }
  };

  const handleInitialSocketConnect = () => {
    if (socketRef.current) {
      const roomData = {
        userId: userId,
        roomId: userId,
        //   userOnlineFlag: true,
      };

      socketRef.current.emit(socketEvent.initiateChat, roomData);
      console.log(roomData, "sfsfsfsfsf");
      // socketRef.current.emit("onlineStatusChanged", (data) => {
      //   console.log(data, "onlineStatusChanged");
      // });
      console.log("iugigiugihj");
      handleChatData();
    }
  };
  const receiveMessageHandler = () => {
    console.log("receive");
    if (socketRef.current) {
      setIsLoading(true);
      socketRef.current.on("receiveUserMessage", (data) => {
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });
      socketRef.current.on(socketEvent.userMessage, (data) => {
        console.log("Received userMessage:", data);
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });

      socketRef.current.on("receiveUserMessage", () => setIsLoading(false));
      socketRef.current.on(socketEvent.userMessage, () => setIsLoading(false));
    }
  };
  const sendMessageHandler = async () => {
    setShowEmojiPicker(false);
    try {
      if (socketRef.current) {
        const trimmedMessage = message?.trim();
        if (!trimmedMessage) {
          console.log("Cannot send an empty message");
          return;
        }

        console.log("Attempting to send userMessage:", {
          from: userId,
          room_id: userId,
          advisor_id: advisorId,
          message: trimmedMessage,
        });

        socketRef.current.emit(socketEvent.sendMessage, {
          from: userId,
          room_id: userId,
          to: advisorId,
          message: trimmedMessage,
        });

        console.log("userMessage event emitted");

        setMessage("");
      } else {
        console.log("Socket not initialized");
      }
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };

  const userMessageHandler = async () => {
    try {
      if (socketRef.current) {
        socketRef.current.emit(socketEvent.userMessage, {
          from: userId,
          room_id: userId,
          // advisor_id: advisorId,
          message: message?.trim(),
        });
        setMessage("");
      }
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };

  const handleFileSelect = (files) => {
    if (files.length > 0) {
      const selectedFile = files[0];

      console.log("Selected file:", selectedFile);
    }
  };
  const disconnectSocket = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.disConnectSocket,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmojiClick = (event, emojiObject) => {
    console.log(event, emojiObject, "aasasasas");
    if (event?.emoji) {
      setMessage((prevMessage) => prevMessage + event.emoji);
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };
  const handleVoiceInput = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      console.log("Voice recognition started.");
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Transcript:", transcript);
      setMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Voice recognition ended.");
      setIsListening(false);
    };

    recognition.start();
  };

  useEffect(() => {
    // Get the last message element
    const lastMessage = chatScreen?.current?.lastChild;
    if (lastMessage) {
      // Scroll the last message into view
      lastMessage.scrollIntoView({ block: "end" });
    }
  }, [messageList]);

  return (
    <Box style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}>
      <Container maxWidth="lg">
        <div
          className="masterMindCommunityContainer"
          style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}
        >
          <div
            className="header"
            style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              />
            </IconButton>
            <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
              Advisor Chat
            </h2>
          </div>

          <div style={{ position: "relative" }} className="chatContainer">
            <Box
              className="chatList"
              sx={{
                background: currentTheme == "DARK" ? "#000" : "#fff",
                height: "70vh",
                display: isMediumScreen ? "none" : "block", // Hide on medium screens
              }}
            >
              <div className="header"></div>
            </Box>

            {initialLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  position: "absolute",
                  top: "10%",
                  left: "40%",
                  width: "300px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div className="chatScreen">
                <div
                  className="header"
                  style={{
                    background: currentTheme == "DARK" ? "#000" : "#fff",
                  }}
                >
                  {/* <span></span> */}
                  <p
                    style={{
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    {advName}
                  </p>
                </div>
                <Box
                  // component={OverlayScrollbarsComponent}
                  // className=" scrollBarCustomColor"
                  style={{
                    overflow: "scroll",
                    height: "50vh",
                    position: "relative",
                  }}
                >
                  {" "}
                  <div className="chatDetails" ref={chatScreen}>
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        textAlign: "center",
                      }}
                    >
                      {messageList.length <= 0
                        ? "There are currently no messages. Feel free to start the conversation!"
                        : ""}
                    </div>

                    {messageList?.map((data, i) => {
                      {
                        {
                          /* console.log(data, "ssssssssssss"); */
                        }
                      }

                      const isSentByUser = data.from === userId;
                      return (
                        <div
                          key={i}
                          className={`chatBubble ${
                            isSentByUser ? "chatBubbleSelf" : "chatBubbleOther"
                          }`}
                        >
                          <div>
                            <span
                              style={{
                                color: "#c25208",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {data?.user_unique_id}
                            </span>
                          </div>
                          <div style={{ wordBreak: "keep-all" }}>{data?.message}</div>

                          <span className="bubbleChatDate">
                            {data?.createdAt
                              ? dayjs(data?.createdAt)?.format("lll")
                              : "NA"}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Box>
                <div
                  className="chatKeyboard"
                  style={{
                    background: currentTheme == "DARK" ? "#000" : "#fff",
                  }}
                >
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileSelect(e.target.files)}
                  />

                  <div
                    className="keypad"
                    style={{
                      background: currentTheme == "DARK" ? "#000" : "#fff",
                    }}
                  >
                    <input
                      placeholder="Type a message..."
                      value={message}
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                      onChange={(e) => {
                        setMessage(e?.target?.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sendMessageHandler();
                        }
                      }}
                    />
                    <KeyboardVoice
                      onClick={handleVoiceInput}
                      className={isListening ? "listening" : ""}
                      style={{
                        margin: "0 25px 0 0",
                        color: currentTheme == "DARK" ? "#fff" : "#909090",
                        cursor: "pointer",
                      }}
                    />
                    {/* <VoiceInputButton onVoiceInput={handleVoiceInput} /> */}
                    <SentimentSatisfiedAlt
                      style={{
                        color: currentTheme === "DARK" ? "#fff" : "#909090",
                        margin: "0 29px 0 0",
                        cursor: "pointer",
                      }}
                      onClick={toggleEmojiPicker}
                    />
                    <div>
                      <IconButton
                        onClick={sendMessageHandler}
                        disabled={!message}
                      >
                        <Send />
                      </IconButton>
                    </div>
                  </div>
                  {showEmojiPicker && (
                    <EmojiPicker
                      style={{
                        position: " absolute",
                        bottom: "83px",
                        left: "450px",
                      }}
                      onEmojiClick={handleEmojiClick}
                      disableSearchBar
                      disableSkinTonePicker
                      groupVisibility={{ recently_used: false }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Box>
  );
}
