import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import { BiLineChart } from "react-icons/bi";
import { useSelector } from "react-redux";
import LiveAnnouncement from "../home/LiveAnnouncement";
const moduleCardColor = [
  {
    cardColor: "#ffffbc",
    iconColor: "#fcb83f",
  },
  {
    cardColor: "#d9f1d3",
    iconColor: "#8cb581",
  },
  {
    cardColor: "#dff3f7",
    iconColor: "#6daebb",
  },
  {
    cardColor: "#ffecf5",
    iconColor: "#ff9ccb",
  },
  {
    cardColor: "#f2e1ff",
    iconColor: "#c173ff",
  },
];

const CourseModuleView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const data = state?.data;
  console.log(data);

  const [moduleList, setModuleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  console.log("datamodule==>>>", moduleList);
  useEffect(() => {
    console.log(state);
    if (!data) {
      navigate(-1, { replace: true });
    }
    console.log(data, "asdbzkjasasd");
    getCourseModules();
  }, []);

  const getCourseModules = async () => {
    try {
      setIsLoading(true);
      const res = await customAxios({
        url: ApiConfig.getAllCourseModules,
        method: "GET",
        params: {
          course_id: data?.courseDetails?.id || data?.course?.id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      setIsLoading(false);
      console.log(res?.data, "asnjadzkjbxa");
      setModuleList(res?.data?.result?.moduleList || []);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Container maxWidth={"lg"}>
      {" "}
      <Grid
        container
        item
        xs={12}
        alignItems={"center"}
        style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
        mb={10}
      >
        <Grid container xs={12} justifyContent={"center"} alignItems={"center"}>
          <Grid container item xs={12} justifyContent={"space-between"}>
            <Box>
              {" "}
              <Typography
                variant="h4"
                fontSize="45px"
                fontWeight={"600"}
                mt={4}
                style={{
                  backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                Learning & Lives
              </Typography>
              <Typography
                fontSize="25px"
                variant="h6"
                mt={2}
                style={{
                  backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                Join & Grow
              </Typography>
            </Box>

            <Box>
              {" "}
              <LiveAnnouncement
                courseId={
                  data?.course?.course_id
                    ? data?.course?.course_id
                    : data?.course?.id
                }
              />
            </Box>
          </Grid>

          {/* card-start */}
          <Grid
            container
            item
            xs={12}
            mt={4}
            sx={{ borderTop: "1px solid #cccccc", py: 2 }}
          >
            <Typography
              variant="h4"
              fontWeight={"500"}
              style={{
                color: currentTheme == "DARK" ? "#fff" : "#000",
                marginTop: "20px",
              }}
            >
              {data?.courseDetails?.courseName
                ? data?.courseDetails?.courseName + " - Modules"
                : data?.course?.courseName
                ? data?.course?.courseName + " - Modules"
                : "NA"}
            </Typography>

            <Grid container item xs={12} sx={{ gap: 4, mt: 2 }}>
              {isLoading ? (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"center"}
                  alignItems={"center"}
                  minHeight={"300px"}
                >
                  <CircularProgress size={"40px"} sx={{ color: "#cc3c0b" }} />
                </Grid>
              ) : moduleList?.length == 0 ? (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h5">No Module Found</Typography>
                </Grid>
              ) : (
                moduleList?.map((value, index) => {
                  const color = moduleCardColor[index % moduleCardColor.length];

                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}
                      bgcolor={"#FFF3E6"}
                      sx={{
                        py: 3,

                        borderRadius: "12px",
                        cursor:
                          value?.isVideosAvailable == true ? "pointer" : "",

                        border: "1px solid #e46703",
                      }}
                      component={"div"}
                      onClick={() => {
                        if (value?.isVideosAvailable == true) {
                          navigate("/learning-detail/module/videos", {
                            state: { data: value },
                          });
                        } else {
                        }
                      }}
                    >
                      <Box className="displayStart">
                        <img
                          src="/images/moduleicon1.png"
                          alt="moduleIcon"
                          height="40px"
                          width="40px"
                          style={{ marginLeft: "10px" }}
                        />
                        <Typography
                          className="wordBreak"
                          variant="h6"
                          style={{ marginLeft: "16px", fontSize: "15px" }}
                        >
                          {value?.moduleName ? value?.moduleName : "NA"}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
          {/* card-end */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CourseModuleView;
