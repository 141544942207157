import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LoginLayoutWrapper = styled("div")({
  height: "100dvh",
  width: "100dvw",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  position: "relative",
  paddingLeft: "80px",
  "@media (max-width:1200px)": {
    paddingLeft: "0px ",
    padding: "20px",
    overflow: "auto",
  },
});

const CompanyLogoContainer = styled("img")({
  position: "absolute",
  top: "20px",
  width: "120px",
  "@media(max-width:1024px)": {
    left: "20px",
  },
});

const LoginLayoutImageContainer = styled("img")({
  width: "50%",
  height: "100vh",
  overflow: "hidden",

  //   objectFit: "contain",
  "@media(max-width:1200px)": {
    display: "none",
  },
});

const SignUpLayout = ({ children }) => {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  return (
    <LoginLayoutWrapper
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      <Link to="/">
        <CompanyLogoContainer src={"/images/boostlogo2.png"} />
      </Link>

      {children}
      <LoginLayoutImageContainer
        component={"img"}
        sx={{ objectFit: "cover" }}
        //src={"/images/SignUp.png"}
        src={"/images/SignUppage.png"}
      />
    </LoginLayoutWrapper>
  );
};

export default SignUpLayout;
